import httpClient from "api/httpClient";

import { INotification } from "./notification.types";

export class NotificationService {
  private static prefix = "/notification";

  static getNotifications(account: string) {
    return httpClient.get<INotification[]>(this.prefix, {
      headers: {
        address: account
      }
    });
  }
  static readAll(account: string, ids: string[]) {
    return httpClient.post<INotification[]>(
      this.prefix + "/read",
      {
        ids
      },
      {
        headers: {
          address: account
        }
      }
    );
  }
}
