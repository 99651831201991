import { IBankNode } from "services/bankNode/bankNode.types";
import { ILoan } from "services/loan/loan.types";

export enum NotificationType {
  REQUEST_RECEIVED = "REQUEST_RECEIVED",
  LOAN_DEPLOYED = "LOAN_DEPLOYED",
  LOAN_SLASHED = "LOAN_SLASHED",
  LOAN_COMPLETED = "LOAN_COMPLETED",
  LOAN_REJECTED = "LOAN_REJECTED",
  LOAN_APPROVED = "LOAN_APPROVED",
  LOAN_ISSUED = "LOAN_ISSUED",
  LOAN_EDITED = "LOAN_EDITED",
  COLLATERAL_WITHDRAWN = "COLLATERAL_WITHDRAWN",
  LOAN_DUE_REMINDER = "LOAN_DUE_REMINDER",
  LOAN_OVERDUE = "LOAN_OVERDUE",
  LOAN_OVERDUE_GRACE_PERIOD = "LOAN_OVERDUE_GRACE_PERIOD",
  LOAN_PROPOSAL = "LOAN_PROPOSAL",
  LOAN_PROPOSAL_ACCEPTED = "LOAN_PROPOSAL_ACCEPTED"
}

export type INotification = {
  _id: string;
  updatedAt: string;
  createdAt: string;
  actor: string;
  receiver: string;
  notificationType: NotificationType;
  read: boolean;
  deleted: boolean;
  url: string;
  description: string;
  bankNode: IBankNode;
  loan: ILoan;
};
