import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";

export const useSignMessage = (message = `${new Date().getTime() / 1000}`) => {
  const { library, account } = useWeb3React();
  const signer = library?.getSigner(account);

  const sign = useCallback(async () => {
    const signature = await signer?.signMessage(message);
    const address = await signer?.getAddress();

    return {
      signature,
      address,
      message
    };
  }, [message, signer]);

  return { sign };
};
