import config from "contracts/config";

import { compareAddress } from "./common";

export interface Token {
  icon: string;
  label: string;
  value: string;
  decimals: number;
}

export const tokens: Token[] = [
  {
    icon: "/images/usdt.svg",
    label: "USDT",
    value: config.USDT,
    decimals: 6
  },
  {
    icon: "/images/usdc.svg",
    label: "USDC",
    value: config.USDC,
    decimals: 6
  }
  // {
  //   icon: "/images/dai.svg",
  //   label: "DAI",
  //   value: config.DAI,
  //   decimals: 18
  // },
  // {
  //   icon: "/images/busd.svg",
  //   label: "BUSD",
  //   value: config.BUSD,
  //   decimals: 18
  // }
];

export const collateralTokens: Token[] = [
  {
    icon: "/images/weth.svg",
    label: "WETH",
    value: config.WETH,
    decimals: 18
  },
  {
    icon: "/images/wbtc.svg",
    label: "WBTC",
    value: config.WBTC,
    decimals: 8
  }
];

const allTokens = [
  ...tokens,
  ...collateralTokens,
  {
    icon: "/images/dai.svg",
    label: "DAI",
    value: config.DAI,
    decimals: 18
  }
];

export const getToken = (address: string | undefined) =>
  allTokens.find((token) => compareAddress(token.value, address));
