import clsx from "clsx";
import { useTheme } from "hooks/common/useTheme";
import { BsMoonFill, BsSunFill } from "react-icons/bs";

interface Props {
  className?: string;
}

export const ThemeSwitch: React.FC<Props> = ({ className }) => {
  const { toggleTheme, isDark } = useTheme();
  const classes = clsx("text-xl mr-4 cursor-pointer text-black dark:text-yellow-700", className);

  return isDark ? (
    <BsMoonFill className={classes} onClick={toggleTheme} />
  ) : (
    <BsSunFill className={classes} onClick={toggleTheme} />
  );
};
