import { FrameConnector } from "@web3-react/frame-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { isMainnet } from "utils/common";

export const currentChainId = isMainnet ? 1 : 5;

const infuraId = process.env.REACT_APP_INFURA_ID;

const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/" + infuraId,
  42: "https://kovan.infura.io/v3/" + infuraId,
  5: "https://goerli.infura.io/v3/" + infuraId
};

export const metamaskLink =
  "https://metamask.app.link/dapp/" + (isMainnet ? "app.bnplpay.co/" : "bnpl-testnet.netlify.app/");

export const injected = new InjectedConnector({});

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  chainId: currentChainId,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[currentChainId],
  appName: "BNPL"
});

export const frame = new FrameConnector({ supportedChainIds: [currentChainId] });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { ethereum } = window as any;

export const switchNetwork = () => {
  const id = `0x${currentChainId.toString(16)}`;
  ethereum
    .request({ method: "wallet_switchEthereumChain", params: [{ chainId: id }] })
    // eslint-disable-next-line no-console
    .catch((error: Error) => console.log(`Error: ${error.message}`));
};
