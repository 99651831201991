import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback } from "react";
import { setBankNodeAddress, setBankNodePendingLoansCount } from "redux/auth/authSlice";

import { useAppDispatch, useAppSelector } from "./common/redux";
import { useBNPLFactoryContract } from "./useContracts";

export const useGetBankNodeAddress = () => {
  const { account } = useWeb3React();
  const { bankNodeAddress } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const factoryContract = useBNPLFactoryContract();

  // const fetchBankNodePendingLoansCount = useCallback(
  //   async (address?: string) => {
  //     try {
  //       if (!bankNodeAddress && !address) return;
  //       const { data } = await LoanService.getBankNodePendingLoansCount(address || bankNodeAddress);
  //       dispatch(setBankNodePendingLoansCount(Number(data || 0)));
  //     } catch (error) {}
  //   },
  //   [dispatch, bankNodeAddress]
  // );

  const fetchNodeAddress = useCallback(async () => {
    dispatch(setBankNodeAddress(""));
    dispatch(setBankNodePendingLoansCount(0));
    try {
      const nodeAddress = await factoryContract?.operatorToNode(account!);
      if (nodeAddress && nodeAddress !== ethers.constants.AddressZero) {
        dispatch(setBankNodeAddress(nodeAddress));
        return nodeAddress;
      }
    } catch (error) {}
  }, [account, dispatch, factoryContract]);

  return {
    fetchNodeAddress,
    bankNodeAddress
  };
};
