import clsx from "clsx";
import { Card } from "components/Common/Card/Card";
import { useOnClickOutside } from "hooks/common/useOnClickOutside";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";

type DialogSize = "md" | "lg" | "xl" | "3xl";

interface Props {
  className?: string;
  wrapperClass?: string;
  open: boolean;
  onClose?: () => void;
  onExited?: () => void;
  children?: React.ReactNode;
  disableClose?: boolean;
  hideCloseIcon?: boolean;
  size?: DialogSize;
}

export const Dialog: React.FC<Props> = ({
  className,
  wrapperClass,
  open,
  onClose,
  onExited,
  children,
  disableClose,
  hideCloseIcon,
  size = "md"
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => !disableClose && onClose?.());

  useEffect(() => {
    if (open) document.documentElement.classList.add("stop-scrolling");
    else document.documentElement.classList.remove("stop-scrolling");
  }, [open]);
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      unmountOnExit
      classNames="dialog"
      in={open}
      nodeRef={nodeRef}
      timeout={{ enter: 500, exit: 300 }}
      onExited={onExited}
    >
      <div
        ref={nodeRef}
        className="backdrop-blur-lg overflow-y-auto bg-overlay overflow-x-hidden fixed right-0 left-0 top-0 z-999 flex justify-center items-center h-screen w-screen max-h-screen"
      >
        <Card
          ref={ref}
          className={clsx(
            wrapperClass,
            "overflow-y-auto relative w-full h-full max-h-full md:h-auto",
            {
              "max-w-md": size === "md",
              "max-w-lg": size === "lg",
              "max-w-xl": size === "xl",
              "max-w-3xl": size === "3xl"
            }
          )}
          defaultClass="md:rounded-xl p-4 sm:p-6"
        >
          {!hideCloseIcon && (
            <AiOutlineClose
              className="absolute top-4 right-4 text-xl cursor-pointer rounded border border-edge p-0.5 bg-secondary dark:bg-black-450"
              onClick={onClose}
            />
          )}
          <div className={clsx(className, "px-2 text-center")}>{children}</div>
        </Card>
      </div>
    </CSSTransition>
  );
};
