/* eslint-disable react/no-unescaped-entities */

const Privacy: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <p className="tg-title-h3 text-title">Personal Data and Privacy Policies</p>
      <p>
        <strong>1. General Privacy Policy</strong>
      </p>
      <p>
        The issuer of the BNPL Tokens (&ldquo;<strong>Tokens</strong>&rdquo;) and the corporate
        entity launching the BNPL Pay Blockchain Network (altogether &ldquo;<strong>Service</strong>
        &rdquo;) is BNPL Pay Holdings Foundation, a company limited by guarantee incorporated in
        Panama (the &ldquo;<strong>Foundation</strong>&rdquo;). Please note that we take the
        protection of your personal data very seriously. We treat your personal data confidentially
        and according to the prevailing legal data protection regulations and this privacy policy
        (this &ldquo;<strong>Privacy Policy</strong>&rdquo;).
      </p>
      <p>
        Your provision of personal data, such as names, addresses or e-mail addresses, is strictly
        on a voluntary basis. However, if you choose not to provide, or to permit the use,
        disclosure and/or processing of, the personal data we require, it may not be possible for us
        to provide you with the information, products and/or services you want. Subject to the terms
        of this Privacy Policy, this data will not be passed on to third parties without your
        permission or without being required by law.
      </p>
      <p>
        However, we would like to point out that data transmissions via internet (e.g. e-mail
        communication) cannot be entirely secure and may have security vulnerabilities. A complete
        protection of personal details against unauthorised access by third parties is not possible.
      </p>
      <p>
        <strong>
          IF YOU PROVIDE PERSONAL DATA TO US, YOU CONSENT TO THE COLLECTION, USE AND DISCLOSURE OF
          SUCH PERSONAL DATA IN ACCORDANCE WITH THIS POLICY. IF YOU PROVIDE THE PERSONAL DATA OF
          ANOTHER INDIVIDUAL, IT MEANS THAT YOU HAVE INFORMED HIM/HER OF THE PURPOSES FOR WHICH WE
          REQUIRE HIS/HER PERSONAL DATA AND HE/SHE HAS CONSENTED TO THE COLLECTION, USE AND
          DISCLOSURE OF HIS/HER PERSONAL DATA IN ACCORDANCE WITH THIS POLICY.{" "}
        </strong>
      </p>
      <p>
        <strong>1.1 Database requests</strong>
      </p>
      <p>
        In order to comply with international sanctions and applicable regulation for securities and
        to fight money-laundering or the financing of terrorism, we may need to check any personal
        data provided by you against publicly available databases.
      </p>
      <p>
        <strong>1.2 Contact form</strong>
      </p>
      <p>
        We use Hubspot for contact form management and by submitting forms, you acknowledge and
        agree that the information you provide will be transferred for processing in accordance
        withtheir prevailing terms, conditions and privacy policies. If you send enquiries to us via
        these forms, your data entered into the contact form, including the stated contact data, are
        stored for the purpose of dealing with your enquiry and in case of additional enquiries.
      </p>
      <p>
        <strong>1.3 Newsletter</strong>
      </p>
      <p>
        We may issue newsletters to you to give updates on the Service. If you would like to receive
        the newsletter offered on this Service, we require your email address as well as information
        which allows us to check that you are the owner of the provided email address and that you
        agree to receiving the newsletter. Further data may also be collected, if necessary. We use
        those data exclusively for the conduct of checks as mentioned above and the sending of the
        requested information. Subject to the terms of this Privacy Policy, we do not transmit such
        data to third parties.
      </p>
      <p>
        The given permission to the storage of the data, the email address as well as the use of
        such for the purpose of sending the newsletter can be withdrawn at any time via the
        "Unsubscribe" link within the newsletter.
      </p>
      <p>
        <strong>1.4 Processing of data (customer data and contract data)</strong>
      </p>
      <p>
        Some of our services use Google Analytics to collect pseudonymous usage data, which is used
        for the sole purpose of helping to improve our products for our users. This data will never
        be associated with any personal user data.
      </p>
      <p>
        In addition, you acknowledge that the purpose of this Service is to provide information
        about the Foundation, the Service and the Auctions . If you wish to submit or have submitted
        personal data to us for the purpose of being registered for participation in the Auctions
        and/or as a potential acquirer of Tokens, you consent to our collection, processing, use and
        disclosure of such personal data for the purposes of such registration and also the issuance
        of Tokens for the purposes of the Auctions.
      </p>
      <p>
        We also reserve the right to monitor, check and process your personal data if necessary to
        investigate or obtain evidence concerning any complaint, claim or dispute or any actual or
        suspected illegal or unlawful conduct or to aid in such investigation or evidence gathering
        by any law enforcement or regulatory authorities.
      </p>
      <p>
        We may also collect, process, use and disclose personal data where required or permitted by
        law for any purpose.
      </p>
      <p>
        Notwithstanding anything to the contrary in this Privacy Policy, we may disclose personal
        data to third parties in order to achieve any purposes stated herein. The third parties
        include:
      </p>
      <ul>
        <li>
          our service providers and contractors, including third party vendors that provide data
          processing or management services;
        </li>
        <li>our business partners and affiliates;</li>
        <li>other holders or users of Tokens;</li>
        <li>
          the Auction Operator or any person or entity involved in the payment for Tokens or
          Services provided to you; and
        </li>
        <li>
          law enforcement authorities, regulatory authorities, statutory bodies or public agencies
          for the purposes of complying with their requirements, policies, directives or requests.
        </li>
      </ul>
      <p>
        <strong>
          1.5 Transmission of data on contract conclusion for services and digital contents
        </strong>
      </p>
      <p>
        Except as otherwise stated in this Policy above or expressly agreed on, we transmit personal
        data to third parties only if it is necessary in relation to the Service including
        transmitting data to the commissioned credit institution in charge with the payment.
      </p>
      <p>
        <strong>1.6 Commercial use policy</strong>
      </p>
      <p>
        Only in the event and for the time that you give us your consent, we will use your personal
        data and/or transmit it to our business partners and affiliates of BNPL Pay Foundation in
        order for us or our business partners to inform you via e-mail about upcoming opportunities
        or to market and promote our respective products and services.
      </p>
      <p>
        You may view, withdraw and (re-)give your declaration of consent at any time by sending an
        e-mail to the provider of this Service. To withdraw your consent, you may also contact us
        under the address published in the footer of the e-mail/imprint/legal notice.
      </p>
      <p>
        <strong>1.7 Right of access to and correction of personal data</strong>
      </p>
      <p>
        You have the right to be informed about your personal data in our possession or under our
        control, their origin and addressee and the purpose of data processing as well as the right
        to correction of those data at any time. For further questions and questions concerning
        personal data, feel free to contact us under the address published in the imprint/legal
        notice or send an email to :&nbsp;contact@BNPL Pay.foundation.
      </p>
      <p>
        <strong>1.8 Objection to commercial mails</strong>
      </p>
      <p>
        The providers of this Service or the information, products or services featured on this
        Website may publish their contact information in the Footer of e-mails/Legal Note/Imprint.
        The use of such contact information by third parties for the purpose of distributing
        unsolicited advertisements or other commercial information is prohibited. The operators of
        this Service reserve the right to take legal measures in case of being sent unsolicited
        commercial information, e.g. spam mails etc.
      </p>
      <p>
        <strong>1.9 Amendment of this privacy policy</strong>
      </p>
      <p>
        We may amend this Privacy Policy from time to time and will make available the updated
        Privacy Policy via this website. Each time we collect, process, use or disclose personal
        data, the latest version of this Privacy Policy in force at the time will apply.
      </p>
      <p>
        <strong>2. Privacy Policy EU</strong>
      </p>
      <p>
        The Foundation (or, &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; or
        &ldquo;<strong>our</strong>&rdquo;) is a Panama based company limited by guarantee. Its
        mandate is to ensure open access to the BNPL Pay blockchain technology, mint and launch the
        BNPL Tokens (&ldquo;<strong>Tokens</strong>&rdquo;), provide long-term governance, community
        and ecosystem, and be a custodian of funds raised (the &ldquo;<strong>Services</strong>
        &rdquo;).
      </p>
      <p>
        The Foundation is committed to demonstrating the highest level of integrity in dealing with
        our customers, Token holders and other business partners. When you are using the
        Foundation&rsquo;s Services and electronic media, we may collect, process and/or disclose
        data that identify you or make you identifiable (&ldquo;<strong>Personal Data</strong>
        &ldquo;) in accordance with this privacy policy (&ldquo;<strong>Policy</strong>&rdquo;).
        Further, we may either receive your Personal Data directly from you when you are sending
        e-mails to us or provide your Personal Data otherwise in the course of other interactions
        with us, or indirectly from third parties who legally provide your Personal Data to us.
      </p>
      <p>
        This Policy is meant to inform you, which Personal Data we collect, store, process, use
        and/or disclose, for which purposes and on which legal basis. We further inform you about
        your rights to protect your Personal Data.
      </p>
      <p>
        This Policy may be amended or updated from time to time to reflect changes in our practices
        with respect to the Processing of Personal Data, or changes in applicable law. We encourage
        you to read this Policy carefully, and to regularly check this page to review any changes we
        might make in accordance with the terms of this Policy. Your continued use of our Services
        or website constitutes your agreement to be bound by this Policy, as amended or updated from
        time to time.
      </p>
      <p>
        <strong>
          2.1 Consent to international transfer in countries with lower level of data protection
        </strong>
      </p>
      <p>
        If you do not wish your Personal Data to be transferred to third countries outside the
        EU/EEA, please do not tick the consent box and do not send any Personal Data to the
        Foundation.
      </p>
      <p>
        Please note that the Foundation collects your Personal Data directly from the country where
        you are based and may store it on servers outside EU/EEA, which may be operated by third
        party service providers. The Foundation needs to involve the third party service providers
        due to their legitimate interests in having a secure and sufficient data storage system. The
        Foundation chooses the respective third party service providers with due care and pays
        attention that such service providers respect the applicable privacy laws, are aware of the
        importance of protection of personal data and implement &ldquo;state-of-the-art&rdquo;
        technical and organizational measures. Therefore, your potential interests in not
        transferring your personal data do not outbalance the legitimate interest of BNPL Pay
        Foundation.
      </p>
      <p>
        <strong>
          WHEN CONSENTING TO THIS PRIVACY POLICY, YOU ALSO CONSENT TO SUCH INTERNATIONAL TRANSFER TO
          AND PROCESSING OF YOUR PERSONAL DATA BY SUCH THIRD PARTIES IN THIRD COUNTRIES OUTSIDE THE
          EU/EEA. PLEASE FIND A LIST OF THE THIRD PARTY SERVICE PROVIDERS AND THE RESPECTIVE THIRD
          COUNTRIES INVOLVED IN SUCH PROCESSING OF YOUR PERSONAL DATA UNDER SECTION 2.6.
        </strong>
      </p>
      <p>
        <strong>WITHDRAWAL OF CONSENT</strong>
      </p>
      <p>
        In the rare circumstances where we ask for your consent to process your Personal Data, any
        consent is provided freely. If you give your consent, you have the right to withdraw your
        consent at any time. The withdrawal of consent does not affect the lawfulness of Processing
        based on consent before its withdrawal. After your withdrawal we will stop to Process your
        Personal Data, including storage. This paragraph is only relevant for Processing that is
        entirely voluntary &ndash; it does not apply for Processing that is necessary or obligatory
        in any way. To withdraw your consent, please send us an e-mail to&nbsp;
        <a href="mailto:info@bnplpay.co">info@bnplpay.co</a>.
      </p>
      <p>
        <strong>2.2 Which Personal Data we process</strong>
      </p>
      <p>
        For the purposes of providing the Services and the conducting of the Auctions, the
        categories of Personal Data about you that we or our authorized third party data Processors
        may Process include:
      </p>
      <ul>
        <li>
          <strong>Personal</strong>&nbsp;<strong>details</strong>: name, gender, date of birth /
          age, nationality, passport or national ID number, social security number, tax
          identification number;
        </li>
        <li>
          <strong>Contact details</strong>: address, e-mail address, telephone number, social media
          account details;
        </li>
        <li>
          <strong>Financial details</strong>: bank information for payments, credit card information
          for payments, utility bill, credit report, other financial details with respect to
          &lsquo;Accredited Investor&rsquo; verification;
        </li>
        <li>
          <strong>Corporate details</strong>: name, place of registration, registration number,
          transparency register number, details with respect to articles of association and other
          similar documents / certificates, details with respect to shareholders and/or beneficial
          owners (including their personal and contact details);
        </li>
        <li>
          technical information of your device which you use for orders and communication (cell
          phone, tablet, notebook, personal computer, etc. (e.g. IP address),
        </li>
        <li>log in information (e-mail address, password); and</li>
        <li>your public cryptocurrency wallet address.</li>
      </ul>
      <p>
        <strong>2.3 How we collect your Personal Data</strong>
      </p>
      <p>We may collect Personal Data about you from the following sources:</p>
      <ul>
        <li>When you contact us via e-mail, telephone or by any other means;</li>
        <li>
          We may collect your Personal Data in the ordinary course of our relationship with you;
        </li>
        <li>When we provide you with access to the BNPL Pay blockchain protocol;</li>
        <li>
          Where you have manifestly chosen to make such Personal Data public, including via social
          media profiles;
        </li>
        <li>
          When we receive your Personal Data from third parties who legally provide it to us, such
          as credit reference agencies or law enforcement agencies;
        </li>
        <li>
          When you visit any of our websites or use any features or resources available on or
          through our websites. When you visit our website, your device and browser may
          automatically disclose certain information (such as device type, operating system, browser
          type, browser settings, IP address, language settings, dates and times of connecting to a
          website and other technical communications information), some of which may constitute
          Personal Data;
        </li>
        <li>When you submit your resume/CV to us for a job application;</li>
        <li>When you acquire the Tokens; and</li>
        <li>
          When you are participating in the<strong>&nbsp;Auctions&nbsp;</strong>(as defined which is
          accessible at the Auction Terms).
        </li>
      </ul>
      <p>
        <strong>2.4 Creation of Personal Data</strong>
      </p>
      <p>
        In the course of your interaction on the BNPL Pay blockchain platform, we may also create
        Personal Data about you, such as records of your interactions with us and details of your
        transaction history.
      </p>
      <p>
        <strong>2.5 For which purposes we use your Personal Data</strong>
      </p>
      <p>
        We use your Personal Data to provide, maintain and improve our Services, to grant you access
        to BNPL Pay blockchain and enable you the use of the Tokens. For marketing purposes, we use
        your Personal Data only if and as long as we have received your explicit prior consent and
        in accordance with respectively applicable additional legal requirements in your
        jurisdiction. We do not sell your Personal Data to third parties.
      </p>
      <p>
        In addition, you acknowledge that the purpose of our Service is to provide information about
        the Project and the Auctions . If you submit Personal Data to us or our authorized
        Processors for the purposes of participation in the Auctions and/or as a potential acquiror
        of the Tokens, we will collect, process and disclose such personal data for the purposes of
        registration for the Auctions and also the issuance, distribution, use and circulation of or
        exchange for the Tokens.
      </p>
      <p>
        <strong>2.6 Lawful basis for Processing Personal Data</strong>
      </p>
      <p>
        In Processing your Personal Data in connection with the purposes set out in this Policy, we
        may rely on one or more of the following legal bases, depending on the circumstances:
      </p>
      <ul>
        <li>
          we have obtained your explicit prior consent to the Processing (this legal basis is only
          used exceptionally in relation to Processing that is entirely voluntary &ndash; it is not
          used for Processing that is necessary or obligatory in any way);
        </li>
        <li>
          the Processing is necessary in connection with any contractual relationship that you may
          enter into with us;
        </li>
        <li>the Processing is required by applicable law;</li>
        <li>the Processing is necessary to protect the vital interests of any individual; or</li>
        <li>
          we have a legitimate interest in carrying out the Processing for the purpose of managing,
          operating or promoting our business, and that legitimate interest is not overridden by
          your interests, fundamental rights, or freedoms.
        </li>
      </ul>
      <p>
        When we are involving third party Processors into the performance of our services and
        contractual obligations and such involvement requires the sharing of Personal Data, we have
        entered with our third party Processors into data processing agreements according to Art. 28
        of the European General Data Protection Regulation (&ldquo;<strong>GDPR</strong>&rdquo;)
        and, as far as required, further appropriate safeguards according to Art. 46 &ndash; 49
        GDPR. The list of third party Processors to which we disclose your Personal Data can be
        requested by e-mail to&nbsp;info@bnplpay.co
      </p>
      <p>
        <strong>
          Categories of third parties &ldquo;Non-Processors&rdquo; to whom we may disclose your
          Personal Data
        </strong>
        .
      </p>
      <p>
        We may also disclose your Personal Data to third parties who are not Processors in terms of
        Art. 28 of the GDPR. The categories of such Non-Processors are:
      </p>
      <ul>
        <li>banks, credit agencies and other financial and/or payment service providers</li>
        <li>
          our group affiliates (list of which can be found requested by email:&nbsp;info@bnplpay.co
        </li>
        <li>other holders or users of BNPL Tokens</li>
        <li>IT-service providers</li>
      </ul>
      <p>
        <strong>2.7 Processing your Sensitive Personal Data</strong>
      </p>
      <p>
        We do not seek to collect or otherwise Process your Sensitive Personal Data, except where:
      </p>
      <ul>
        <li>
          the Processing is required or permitted by applicable law (e.g., to comply with our
          diversity reporting obligations);
        </li>
        <li>
          the Processing is necessary for the detection or prevention of crime (including the
          prevention of fraud);
        </li>
        <li>
          the Processing is necessary for the establishment, exercise or defence of legal rights; or
        </li>
        <li>
          we have, in accordance with applicable law, obtained your explicit consent prior to
          Processing your Sensitive Personal Data (as above, this legal basis is only used in
          relation to Processing that is entirely voluntary &ndash; it is not used for Processing
          that is necessary or obligatory in any way).
        </li>
      </ul>
      <p>
        <strong>2.8 Consequences if we may not collect your Personal Data</strong>
      </p>
      <p>
        We need your Personal Data to provide our Services to you and/or perform our contractual
        obligations towards you. Without providing such Personal Data, we may not be able to provide
        you the services you are intending to receive.
      </p>
      <p>
        <strong>2.9 Consent and withdrawal</strong>
      </p>
      <p>
        Any consent is provided freely. If you give your consent, you have the right to withdraw
        your consent at any time. The withdrawal of consent does not affect the lawfulness of
        Processing based on consent before its withdrawal. After your withdrawal we will stop to
        Process your Personal Data, including storage. This paragraph is only relevant for
        Processing that is entirely voluntary &ndash; it does not apply for Processing that is
        necessary or obligatory in any way.
      </p>
      <p>
        To withdraw your consent, please send us an e-mail to&nbsp;info@bnplpay.co&nbsp;or a letter
        to BNPL Pay Foundation Ltd, 1 George Street, #10-01, One George Street, Singapore
        (049145).&nbsp;
      </p>
      <p>
        <strong>2.10 Cookies</strong>
      </p>
      <p>
        We may use cookies from time to time. A Cookie is a unique text file that a Web site can
        send to your browser software. Cookies do not include any Personal Data. We may use Cookies
        to identify the browser you are using so that our website displays properly. We also use
        cookies in various places on our website in order to document your visit to our website and
        allow for a more efficient website design.
      </p>
      <p>
        If you do not want to allow us to use Cookies, you can disable Cookie installation via your
        browser setting or refuse the installation of Cookies when prompted to this effect. You also
        have the option of deleting Cookies from your computer&rsquo;s hard disk at any time. The
        Cookies will be deleted automatically after the web session of the browser ended.
      </p>
      <p>
        <strong>2.11 Marketing Activities</strong>
      </p>
      <p>
        In case you consented to receiving information / newsletters from us, we may transfer your
        Personal Data to our business partners in order for us or our business partners to inform
        you via e-mail about upcoming opportunities and promote our respective products and
        services.
      </p>
      <p>
        <strong>2.12 Links to Business Partner and Co-Branded Sites</strong>
      </p>
      <p>
        Certain links contained on BNPL Pay Foundation web sites may direct you to co-branded web
        sites maintained by BNPL Pay Foundation or to companies which BNPL Pay Foundation has
        established business relationships. When you submit information to one of these co-branded
        sites or partner companies, you may be submitting it to both BNPL Pay Foundation and these
        business partners. Under no circumstances may BNPL Pay Foundation be held responsible for
        the privacy practices of these business partners and we therefore strongly encourage you to
        read their respective privacy policies as they may differ from ours.
      </p>
      <p>
        <strong>2.13 When we erase your Personal Data</strong>
      </p>
      <p>
        We erase your Personal Data automatically when they are no longer required for the purposes
        listed above. We also erase your Personal Data according to your request and if further
        storage is neither required nor permitted by applicable laws.
      </p>
      <p>
        <strong>2.14 Your rights related to data privacy</strong>
      </p>
      <p>
        You have the right to request access to and rectification or erasure of your Personal Data,
        or restriction of their Processing. Furthermore, you have the right to object Processing as
        well as to request data portability. If you are in the EU you have the right to file a
        complaint to the responsible European Data Protection Authority.
      </p>
      <p>
        <strong>2.15 Our contact information, Data Controller</strong>
      </p>
      <p>
        If you have a direct business relationship with us, we are Data Controllers according to
        Art. 4 para. 7 GDPR. For any requests you can contact us:
      </p>
      <p>
        Our data privacy officer is Phil Dawson and you can contact us as
        follows:&nbsp;info@bnplpay.co
      </p>
      <p>
        We may process your data on behalf of a contract partner of yours (e.g. internet-shop,
        vendor, seller, service provider). In this case, the privacy policy of your partner applies.
        Please contact your contract partner for more information.
      </p>
      <p>
        <strong>2.16 Definitions</strong>
      </p>
      <ul>
        <li>
          &lsquo;<strong>Controller</strong>&rsquo; means the entity that decides how and why
          Personal Data is Processed. In many jurisdictions, the Controller has primary
          responsibility for complying with applicable data protection laws.
        </li>
        <li>
          &lsquo;<strong>Data Protection Authority</strong>&rsquo; means an independent public
          authority that is legally tasked with overseeing compliance with applicable data
          protection laws.
        </li>
        <li>
          &lsquo;<strong>EEA</strong>&rsquo; means the European Economic Area.
        </li>
        <li>
          &lsquo;<strong>Personal Data</strong>&rsquo; means information that is about any
          individual, or from which any individual is identifiable. Examples of Personal Data that
          we may Process are provided above in this Policy.
        </li>
        <li>
          &lsquo;<strong>Process</strong>&rsquo;, &lsquo;<strong>Processing</strong>&rsquo; or
          &lsquo;<strong>Processed</strong>&rsquo; means anything that is done with any Personal
          Data, whether or not by automated means, such as collection, recording, organisation,
          structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure
          by transmission, dissemination or otherwise making available, alignment or combination,
          restriction, erasure or destruction.
        </li>
        <li>
          &lsquo;<strong>Processor</strong>&rsquo; means any person or entity that Processes
          Personal Data on behalf of the Controller (other than employees of the Controller).
        </li>
        <li>
          &lsquo;<strong>Services</strong>&rsquo; means any services provided by BNPL Pay
          Foundation.
        </li>
        <li>
          &lsquo;<strong>Sensitive Personal Data</strong>&rsquo; means Personal Data about race or
          ethnicity, political opinions, religious or philosophical beliefs, trade union membership,
          physical or mental health, sexual life, any actual or alleged criminal offences or
          penalties, or any other information that may be deemed to be sensitive under applicable
          law.
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
