import config from "contracts/config";
import { ReactNode } from "react";
import { sushiSwapLink } from "utils";

import { paths } from "./paths";

interface HeaderRoute {
  path: string;
  icon?: ReactNode;
  title: string;
  external?: boolean;
  isBorrow?: boolean;
}

export const headerRoutes: Readonly<HeaderRoute[]> = [
  {
    path: paths.index,
    title: "header.routes.home"
  },
  {
    path: paths.bankNodes.index,
    title: "header.routes.lend"
  },
  {
    path: paths.borrow.index,
    title: "header.routes.borrow"
  },
  {
    path: paths.farm,
    title: "header.routes.farm"
  },
  {
    path: "https://docs.bnplpay.co/",
    title: "header.routes.docs",
    external: true
  },
  {
    path: sushiSwapLink(config.BNPL),
    title: "header.routes.buyBNPL",
    external: true
  }
];
