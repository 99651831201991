import { useEffect, useState } from "react";
import useSWR from "swr";

import type { ERC20 } from "../contracts/types";
import useKeepSWRDataLiveAsBlocksArrive from "./useKeepSWRDataLiveAsBlocksArrive";
import useTokenContract from "./useTokenContract";

function getTokenBalance(contract: ERC20) {
  return async (_: string, address: string) => {
    const balance = await contract.balanceOf(address);
    return balance;
  };
}

export default function useTokenBalance(address: string, tokenAddress: string, suspense = false) {
  const contract = useTokenContract(tokenAddress);
  const [decimals, setDecimals] = useState(18);

  const shouldFetch = typeof address === "string" && typeof tokenAddress === "string" && !!contract;

  useEffect(() => {
    const updateDecimals = async () => {
      const d = await contract?.decimals();
      setDecimals(Number(d?.toString()));
    };

    if (contract) updateDecimals();
  }, [contract]);

  const result = useSWR(
    shouldFetch ? ["TokenBalance", address, tokenAddress] : null,
    getTokenBalance(contract as ERC20),
    {
      suspense
    }
  );

  useKeepSWRDataLiveAsBlocksArrive(result.mutate);

  return { ...result, decimals };
}
