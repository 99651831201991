import { Box } from "components/ui/Box/Box";
import { Button } from "components/ui/Button/Button";
import { paths } from "configs/paths";
import { AiFillWechat } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { prettyTx } from "utils";

interface Props {
  user: string;
  count: number;
  bankingNode?: boolean;
  onClose: () => void;
}

export const ChatNotificationItem: React.FC<Props> = ({
  count,
  user,
  bankingNode = false,
  onClose
}) => {
  const navigate = useNavigate();
  return (
    <Box className="relative text-text pb-12" defaultClass="py-6 px-4">
      <b>{count}</b> new messages from <b>{prettyTx(user)}</b>
      <Button
        rounded
        className="absolute right-3 bottom-3 min-w-[110px] px-2"
        size="xs"
        variant="secondary"
        onClick={() => {
          navigate(bankingNode ? paths.chatUser(user) : paths.chatNode(user));
          onClose();
        }}
      >
        <AiFillWechat className="mr-1" />
        Chat
      </Button>
    </Box>
  );
};
