import { useWeb3React } from "@web3-react/core";
import { Login } from "components/Common/Login/Login";
import { Logo } from "components/Common/Logo/Logo";
import TokenBalance from "components/Common/TokenBalance";
import { WalletConnect } from "components/Common/WalletConnect/WalletConnect";
import { Drawer } from "components/ui/Drawer/Drawer";
import config from "contracts/config";
import { useGetBankNodeAddress } from "hooks/useGetBankNodeAddress";
import { useEffect, useState } from "react";

import { HeaderBurger } from "./components/HeaderBurger";
import { HeaderRoutes } from "./components/HeaderRoutes";
import { ThemeSwitch } from "./components/ThemeSwitch";

interface Props {
  wrongNetwork: boolean;
}

export const Header: React.FC<Props> = ({ wrongNetwork }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { account } = useWeb3React();

  const { fetchNodeAddress } = useGetBankNodeAddress();

  // const location = useLocation();

  // const showBanner = useMemo(() => {
  //   return location.pathname === "/" || location.pathname === "/bank-nodes";
  // }, [location]);

  useEffect(() => {
    fetchNodeAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      <Drawer className="lg:hidden fixed" close={() => setShowSidebar(false)} open={showSidebar}>
        <HeaderRoutes className="mb-10" />
        {!wrongNetwork && (
          <TokenBalance
            summary
            className="text-black dark:text-green-700"
            symbol="BNPL"
            tokenAddress={config.BNPL}
          />
        )}
        <ThemeSwitch className="my-2" />
        <Login />
      </Drawer>

      <header className="flex sticky top-0 z-40 py-3 w-full bg-white border-b-2 border-b-edge dark:bg-gray-900">
        <div className="container flex justify-between items-center">
          <Logo />

          <HeaderRoutes className="hidden lg:block" />

          <div className="flex items-center">
            {!wrongNetwork && (
              <TokenBalance
                summary
                className="hidden lg:block text-black dark:text-green-700 mr-2"
                symbol="BNPL"
                tokenAddress={config.BNPL}
              />
            )}
            <ThemeSwitch className="hidden lg:block" />
            <Login className="hidden md:block" />
            <WalletConnect />
            <HeaderBurger onClick={() => setShowSidebar(true)} />
          </div>
        </div>
      </header>
    </>
  );
};
