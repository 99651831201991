import { Badge } from "components/Common/Badge/Badge";
import { useKYC } from "hooks/useKYC";
import React, { useEffect, useState } from "react";

type KYCStatusProps = {
  user: string;
  bankNodeAddress: string;
};

export const KYCStatus: React.FC<KYCStatusProps> = ({ user, bankNodeAddress }) => {
  const { checkKYC } = useKYC(bankNodeAddress, true);
  const [whitelisted, setWhitelisted] = useState<undefined | boolean>();
  useEffect(() => {
    const fetchStatus = async () => {
      const status = await checkKYC(user, false);
      setWhitelisted(!!status);
    };
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (whitelisted === undefined)
    return <div className="animate-pulse w-24 bg-gray-300 h-4 rounded-md " />;
  return (
    <Badge variant={whitelisted ? "success" : "danger"}>
      {whitelisted ? "Whitelisted" : "Not Whitelisted"}
    </Badge>
  );
};
