import clsx from "clsx";
import { footerRoutes } from "configs/footerRoutes";
import { useGetBankNodeAddress } from "hooks/useGetBankNodeAddress";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Props {
  className?: string;
}

export const FooterMenu: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation("layouts");
  const { bankNodeAddress } = useGetBankNodeAddress();

  const getClass = (isActive: boolean) =>
    clsx("hover:text-primary-700 transition-colors flex items-center", {
      "text-primary-700": isActive
    });

  return (
    <nav
      className={clsx(
        "w-full grid grid-cols-1 gap-8 sm:gap-4 sm:grid-cols-2 lg:grid-cols-4",
        className
      )}
    >
      {footerRoutes.map(({ title, items }, index) => (
        <div key={title}>
          <h6 className="tg-content-bold mb-3">{t(title)}</h6>
          <ul className="space-y-3">
            {items.map(({ title, path, external, disable }) => (
              <li key={title} className="tg-main-bold relative text-title">
                {external ? (
                  <a className={getClass(false)} href={path} rel="noreferrer" target="_blank">
                    <span>{t(title)}</span>
                  </a>
                ) : (
                  <NavLink
                    end
                    className={({ isActive }) => getClass(isActive && !disable)}
                    to={path}
                  >
                    <span>{t(title)}</span>
                  </NavLink>
                )}
              </li>
            ))}
            {bankNodeAddress && index === 0 && (
              <li className="tg-main-bold relative text-title">
                <NavLink
                  end
                  className={({ isActive }) => getClass(isActive)}
                  to={"bank-nodes/" + bankNodeAddress}
                >
                  <span>My Node</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      ))}
    </nav>
  );
};
