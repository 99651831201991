import clsx from "clsx";

import styles from "./Loader.module.scss";

interface Props {
  fullScreen?: boolean;
  overlay?: boolean;
}
export const Loader: React.FC<Props> = ({ fullScreen, overlay }) => {
  return (
    <div
      className={clsx({
        "flex items-center justify-center fixed w-full h-full top-0 left-0": fullScreen,
        "bg-black z-100 bg-opacity-40": overlay
      })}
    >
      <div className={styles.loader}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
