import Admin from "pages/admin";
import Kyc from "pages/kyc";
import Applicants from "pages/kyc/applicants";
import Notification from "pages/notification";
import Privacy from "pages/privacy";
import TermsAndConditions from "pages/termsAndConditions";
import React from "react";
import { IndexRouteProps, LayoutRouteProps, PathRouteProps } from "react-router-dom";

import { paths } from "./paths";

const OldFarm = React.lazy(() => import("pages/old-farm"));
const Index = React.lazy(() => import("pages"));
const ChatWithUser = React.lazy(() => import("pages/chatWithUser"));
const ChatWithNode = React.lazy(() => import("pages/chatWithNode"));
const BankNodes = React.lazy(() => import("pages/bank-nodes"));
const Address = React.lazy(() => import("pages/bank-nodes/address"));
const EditBankNode = React.lazy(() => import("pages/bank-nodes/edit"));
const Create = React.lazy(() => import("pages/bank-nodes/create"));
const BorrowList = React.lazy(() => import("pages/borrow"));
const CreateLoan = React.lazy(() => import("pages/borrow/create"));
const EditLoan = React.lazy(() => import("pages/borrow/edit"));
const LoanId = React.lazy(() => import("pages/borrow/loanId"));

export type RouteType =
  | (PathRouteProps & { routes?: undefined; isPrivate?: boolean })
  | (IndexRouteProps & { routes?: undefined; isPrivate?: boolean; path?: undefined })
  | (LayoutRouteProps & { routes: RouteType[]; isPrivate?: boolean; path?: string });

export const routes: Readonly<RouteType[]> = [
  {
    index: true,
    element: <Index />
  },
  {
    path: paths.bankNodes.index,
    element: <BankNodes />
  },
  {
    path: paths.bankNodes.create,
    element: <Create />,
    isPrivate: true
  },
  {
    path: paths.bankNodes.address(),
    element: <Address />
  },
  {
    path: paths.bankNodes.edit(),
    element: <EditBankNode />,
    isPrivate: true
  },
  {
    path: paths.farm,
    element: <OldFarm />
  },
  {
    path: paths.borrow.index,
    element: <BorrowList />,
    isPrivate: true
  },
  {
    path: paths.borrow.create,
    element: <CreateLoan />,
    isPrivate: true
  },
  {
    path: paths.borrow.edit(),
    element: <EditLoan />,
    isPrivate: true
  },
  {
    path: paths.borrow.address(),
    element: <LoanId />,
    isPrivate: true
  },
  {
    path: paths.kyc.index(),
    element: <Kyc />,
    isPrivate: true
  },
  {
    path: paths.kyc.applicants(),
    element: <Applicants />,
    isPrivate: true
  },
  {
    path: paths.termsAndConditions,
    element: <TermsAndConditions />
  },
  {
    path: paths.privacy,
    element: <Privacy />
  },
  {
    path: paths.admin,
    element: <Admin />
  },
  {
    path: paths.notification,
    element: <Notification />,
    isPrivate: true
  },
  {
    path: paths.chatNode(),
    element: <ChatWithNode />,
    isPrivate: true
  },
  {
    path: paths.chatUser(),
    element: <ChatWithUser />,
    isPrivate: true
  },
  {
    path: paths.chatUser(""),
    element: <ChatWithUser />,
    isPrivate: true
  }
];
