import { Card } from "components/Common/Card/Card";
import { NotificationItem } from "components/pages/notification/NotificationItem";
import { Pagination } from "components/ui/Pagination/Pagination";
import { usePaginate } from "hooks/common/usePaginate";
import { INotification } from "services/notification/notification.types";

interface Props {
  list: INotification[];
  bankNotif?: boolean;
  isLoading: boolean;
}

const PAGE_SIZE = 6;
export const Notifications: React.FC<Props> = ({ list, bankNotif = false, isLoading }) => {
  const {
    paginate,
    activePage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageSize,
    previousPage
  } = usePaginate(PAGE_SIZE, list.length);

  return (
    <Card className="flex flex-col gap-2">
      <h3 className="mb-1 font-semibold text-xl text-center mb-3 dark:text-white">
        {bankNotif ? "Banking Node" : "User"} Notifications
      </h3>
      {paginate(list).map((notif) => (
        <NotificationItem key={notif._id} bankNotif={bankNotif} notification={notif} />
      ))}
      {list.length === 0 && !isLoading && (
        <div className="text-center">
          <span className="p-4 tg-caption lg:tg-content opacity-75">No Result</span>
        </div>
      )}
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={activePage}
        pageSize={pageSize}
        previousPage={previousPage}
      />
    </Card>
  );
};
