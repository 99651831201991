import { AnyEventName } from "@sumsub/websdk/types/types";
import SumsubWebSdk from "@sumsub/websdk-react";
import { Loader } from "components/Common/Loader/Loader";
import { Dialog } from "components/ui/Dialog/Dialog";
import { paths } from "configs/paths";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BankNodeService } from "services/bankNode/bankNode.service";

const Kyc: React.FC = () => {
  const { address } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (address) {
          setLoading(true);
          const { data } = await BankNodeService.getKycAccessToken(address);
          setAccessToken(data.token);
        }
      } catch (error) {
        navigate(paths.bankNodes.index);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [address, navigate]);

  const messageHandler = (type: AnyEventName, payload: any) => {
    switch (type) {
      case "idCheck.onApplicantSubmitted":
      case "idCheck.onApplicantResubmitted":
        setShowDialog(true);
        break;
      case "idCheck.applicantStatus":
        if (payload.reviewStatus === "completed") setShowDialog(true);
        break;
      default:
        break;
    }
  };

  return loading || !accessToken ? (
    <Loader fullScreen overlay />
  ) : (
    <div className="py-10">
      <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={async () => {
          try {
            const { data } = await BankNodeService.getKycAccessToken(address!);
            return data.token;
          } catch (error) {
            return "";
          }
        }}
        onMessage={messageHandler}
      />
      <Dialog
        open={showDialog}
        onClose={() => {
          navigate(-1);
          setShowDialog(false);
        }}
      >
        <h6 className="tg-title-h6">Thanks for completing KYC with SumSub</h6>
        The Banking Node will review your application in due course.
        <br /> Please check back for on-chain whitelisting.
      </Dialog>
    </div>
  );
};

export default Kyc;
