import { paths } from "./paths";

interface FooterRoute {
  title: string;
  items: {
    path: string;
    title: string;
    external?: boolean;
    disable?: boolean;
  }[];
}

export const footerRoutes: Readonly<FooterRoute[]> = [
  {
    title: "footer.routes.product",
    items: [
      {
        path: paths.bankNodes.index,
        title: "header.routes.lend"
      },
      {
        path: paths.borrow.index,
        title: "header.routes.borrow"
      },
      {
        path: paths.farm,
        title: "header.routes.farm"
      },
      {
        path: "/",
        title: "footer.routes.governance",
        disable: true
      }
    ]
  },
  {
    title: "footer.routes.docs",
    items: [
      {
        path: "https://medium.com/bnplpay",
        title: "footer.routes.blog",
        external: true
      },
      {
        path: "https://bnplpay.co/file/BNPL%20Pay_Whitepaper.pdf",
        title: "footer.routes.whitePaper",
        external: true
      }
      // {
      //   path: "https://bnplpay.co/image/BNPL_Logo_200.png",
      //   title: "footer.routes.logoKit",
      //   external: true
      // }
    ]
  },
  {
    title: "footer.routes.legal",
    items: [
      {
        path: paths.termsAndConditions,
        title: "footer.routes.termsAndConditions"
      },
      {
        path: paths.privacy,
        title: "footer.routes.privacyPolicy"
      }
    ]
  },
  {
    title: "footer.routes.about",
    items: [
      // {
      //   path: "https://medium.com/bnplpay/bnpl-pay-meet-the-team-cd392990d3dd",
      //   title: "footer.routes.team",
      //   external: true
      // },
      {
        path: "https://bnplpay.co/#ourBackers",
        title: "footer.routes.investors",
        external: true
      },
      // {
      //   path: "https://bnplpay.co/#ourPartners",
      //   title: "footer.routes.security",
      //   external: true
      // },
      {
        path: "https://github.com/peckshield/publications/tree/master/audit_reports/PeckShield-Audit-Report-BNPL-v1.0.pdf",
        title: "footer.routes.peckShield",
        external: true
      },
      {
        path: "mailto:technology@bnplpay.co",
        title: "footer.routes.contactUs",
        external: true
      }
      // {
      //   path: "/",
      //   title: "footer.routes.TG",
      //   external: true
      // }
    ]
  }
];
