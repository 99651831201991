import { Loader } from "components/Common/Loader/Loader";
import { KYCForm } from "components/forms/KYCForm/KYCForm";
import { Dialog } from "components/ui/Dialog/Dialog";
import { useAuth } from "hooks/useAuth";
import { useBankingNodeContract } from "hooks/useContracts";
import React, { useCallback, useEffect, useState } from "react";
import { BankNodeService } from "services/bankNode/bankNode.service";

type UpdateKYCProps = {
  bankNodeAddress: string;
  showEdit: boolean;
  setShowEdit: (val: boolean) => void;
  refetch?: () => void;
};

export const UpdateKYC: React.FC<UpdateKYCProps> = ({
  bankNodeAddress,
  setShowEdit,
  showEdit,
  refetch
}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    sumsubKey: "",
    sumsubToken: ""
  });
  const [requireKYC, setRequireKYC] = useState(true);
  const bankingNodeContract = useBankingNodeContract(bankNodeAddress);
  const { isLoggedIn } = useAuth();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await BankNodeService.getKYCInfo(bankNodeAddress);
      setPayload({
        sumsubKey: data.sumsubKey,
        sumsubToken: data.sumsubToken
      });

      const kyc = await bankingNodeContract?.requireKYC();
      setRequireKYC(!!kyc);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [bankNodeAddress, bankingNodeContract]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Dialog disableClose open={showEdit} onClose={() => setShowEdit(false)}>
      {!loading && (
        <KYCForm
          nodeAddress={bankNodeAddress}
          refetch={() => {
            setShowEdit(false);
            fetchData();
            refetch?.();
          }}
          requireKYC={requireKYC}
          sumsubKey={payload.sumsubKey}
          sumsubToken={payload.sumsubToken}
        />
      )}
      {loading && <Loader />}
    </Dialog>
  );
};
