import { Logo } from "components/Common/Logo/Logo";
import { Socials } from "components/Common/Socials/Socials";
import { useTranslation } from "react-i18next";

import { FooterMenu } from "./components/FooterMenu";

export const Footer: React.FC = () => {
  const { t } = useTranslation("layouts");

  return (
    <footer className="border-t-2 border-t-edge">
      <div className="container py-12 flex flex-col lg:flex-row">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 mb-4 lg:mr-40">
          <Logo />

          <p className="tg-content-bold max-w-[12rem]">{t("footer.description")}</p>

          <Socials className="hidden lg:flex" />
        </div>

        <FooterMenu />

        <Socials className="lg:hidden mt-8" />
      </div>
    </footer>
  );
};
