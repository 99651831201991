import BigNumber from "bignumber.js";
import { BankingNode, ERC20, SushiSwapLpToken } from "contracts/types";

export const approve = async (
  contract: ERC20 | BankingNode | SushiSwapLpToken | null,
  spender: string,
  amount: string,
  account: string | undefined | null
) => {
  if (!account) return;
  try {
    const allowance = await contract?.allowance(account, spender);
    if (new BigNumber(allowance?.toString() || "0").gte(amount)) return;
  } catch (error) {}
  amount = new BigNumber(amount).plus(100).toFixed(0);
  const tx = await contract?.approve(spender, amount);
  await tx?.wait();
};
