import { Box } from "components/ui/Box/Box";
import { Image } from "components/ui/Image/Image";
import blockies from "ethereum-blockies";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { INotification, NotificationType } from "services/notification/notification.types";
import { fromTime } from "utils/date";

interface Props {
  notification: INotification;
  bankNotif?: boolean;
  short?: boolean;
}

const getNotificationDetails = (notification: INotification, bankNotif: boolean) => {
  const loanLink = "/borrow/" + notification.loan._id;

  switch (notification.notificationType) {
    case NotificationType.REQUEST_RECEIVED:
      return {
        title: "Off-Chain Loan Request Received",
        description: `A new loan request from ${notification.loan.contactName} has been submitted.`,
        link: loanLink
      };
    case NotificationType.LOAN_DEPLOYED:
      return {
        title: "On-Chain Loan Request Deployed",
        description:
          "An approved loan request has been deployed on-chain and is ready for issuance.",
        link: loanLink
      };
    case NotificationType.COLLATERAL_WITHDRAWN:
      return {
        title: "Collateral Withdrawn from On-Chain Loan Request",
        description:
          "The collateral has been withdrawn from an on-chain loan request and the loan can no longer be issued.",
        link: loanLink
      };
    case NotificationType.LOAN_SLASHED:
      return {
        title: "Slashing Event Occurred",
        description: bankNotif
          ? "A loan has been slashed due to exceeding the grace period without repayment."
          : "Your loan has not been repaid within the grace period and the loan has been slashed.",
        link: loanLink
      };
    case NotificationType.LOAN_COMPLETED:
      return {
        title: "Loan Repayment Completed",
        description: bankNotif
          ? "A loan has been fully repaid and is now considered completed."
          : "Congratulations! Your loan has been successfully repaid.",
        link: loanLink
      };
    case NotificationType.LOAN_REJECTED:
      return {
        title: "Loan Request Rejected",
        description: `Unfortunately your loan request has been rejected by the ${notification.bankNode.title} Banking Node.`,
        link: loanLink
      };
    case NotificationType.LOAN_APPROVED:
      return {
        title: "Loan Request Approved",
        description: `Your loan request to the ${notification.bankNode.title} Banking Node has been approved and is ready to be deployed on-chain.`,
        link: loanLink
      };
    case NotificationType.LOAN_ISSUED:
      return {
        title: "Loan Request Issued",
        description: `Your loan request to the ${notification.bankNode.title} Banking Node has been issued and the funds transferred to your linked Ethereum address.`,
        link: loanLink
      };
    case NotificationType.LOAN_OVERDUE:
      return {
        title: "Active Loan Overdue",
        description: bankNotif
          ? "An active loan is now considered overdue but within the grace period. Should the repayment not be made before the grace period ends the loan could be slashed."
          : "Your loan is now considered overdue. Please ensure you make a repayment before the grace period ends to avoid further action.",
        link: loanLink
      };
    case NotificationType.LOAN_OVERDUE_GRACE_PERIOD:
      return {
        title: "Active Loan has Passed Grace Period",
        description:
          "An active loan is overdue and has passed the grace period. A slashing event may now occur unless a repayment is made before the slashing event is triggered.",
        link: loanLink
      };
    case NotificationType.LOAN_DUE_REMINDER:
      return {
        title: "Loan Repayment Due Reminder",
        description: "Your loan is due for repayment in less than 7 days.",
        link: loanLink
      };
    case NotificationType.LOAN_EDITED:
      return {
        title: "Off-Chain Loan Request Edited",
        description: `A loan request from ${notification.loan.contactName} has been edited.`,
        link: loanLink
      };
    case NotificationType.LOAN_PROPOSAL:
      return {
        title: "Loan Negotiation",
        description: `There is a new proposal from ${notification.bankNode.title} banking node for your loan request.`,
        link: loanLink
      };
    case NotificationType.LOAN_PROPOSAL_ACCEPTED:
      return {
        title: "Loan Negotiation Accepted",
        description: `${notification.loan.contactName} has accepted the new terms and conditions.`,
        link: loanLink
      };
    default:
      return { title: "", description: "" };
  }
};

export const NotificationItem: React.FC<Props> = ({
  notification,
  bankNotif = false,
  short = false
}) => {
  const blockieImage = useMemo(() => {
    return blockies
      .create({
        seed: notification.actor, // seed used to generate icon data, default: random
        color: "#dfe", // to manually specify the icon color, default: random
        bgcolor: "#aaa", // choose a different background color, default: random
        size: 8, // width/height of the icon in blocks, default: 8
        scale: 3, // width/height of each block in pixels, default: 4
        spotcolor: 13 // each pixel has a 13% chance of being of a third color,
      })
      .toDataURL();
  }, [notification.actor]);

  const details = useMemo(
    () => getNotificationDetails(notification, bankNotif),
    [notification, bankNotif]
  );

  return (
    <Box className="relative text-text" defaultClass="py-6 px-4">
      <h3 className="font-semibold flex items-center mb-2 text-title">
        <Image alt={notification.actor} className="rounded-full mr-2" src={blockieImage} />
        {details.title}
      </h3>

      <p className={short ? "text-sm" : ""}>
        {details.description}
        {!short && (
          <>
            <br />
            Click{" "}
            <Link
              className="hover:text-primary-700 transition-colors italic underline"
              to={{
                pathname: details.link
              }}
            >
              here
            </Link>{" "}
            to review the loan request details.
          </>
        )}
      </p>
      <span className="absolute right-3 bottom-2 text-sm">{fromTime(notification.createdAt)}</span>
      {!notification.read && (
        <span className="flex h-3 w-3 absolute top-2 right-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-500" />
        </span>
      )}
    </Box>
  );
};
