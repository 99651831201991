import BigNumber from "bignumber.js";
import { Stepper, StepperItem } from "components/Common/Stepper";
import { Dialog } from "components/ui/Dialog/Dialog";
import config from "contracts/config";
import { ContractReceipt } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useBankingNodeContract, useRewardControllerContract } from "hooks/useContracts";
import { useCallback, useMemo, useState } from "react";
import { approve } from "utils/approve";
import { compareAddress } from "utils/common";
import { tokens } from "utils/tokens";

import useTokenContract from "./useTokenContract";

export const useBankingNode = (
  bankNodeAddress: string,
  pid: string,
  account: string | null | undefined,
  baseToken = config.USDT,
  cb: (rescipt: ContractReceipt | undefined) => void
) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState<StepperItem[]>([]);

  const bnplContract = useTokenContract(config.BNPL);
  const baseTokenContract = useTokenContract(baseToken);
  const rewardContract = useRewardControllerContract();
  const bankingNodeContract = useBankingNodeContract(bankNodeAddress);

  const baseTokenInfo = useMemo(
    () => tokens.find((token) => compareAddress(baseToken, token.value)),
    [baseToken]
  );

  const getBaseTokenBalance = useCallback(async () => {
    const amount = await bankingNodeContract?.balanceOf(account!);
    if (!amount) return "0";
    return amount.toString();
  }, [account, bankingNodeContract]);

  const claimBNPL = useCallback(async () => {
    setSteps([
      {
        label: "Claim BNPL"
      }
    ]);
    setCurrent(1);
    setError(false);
    setOpen(true);
    try {
      const tx = await rewardContract?.deposit(pid, "0");
      const rescipt = await tx?.wait();
      setCurrent(2);
      cb(rescipt);
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  }, [cb, pid, rewardContract]);

  const setRewardDistribution = useCallback(async () => {
    setSteps([
      {
        label: "Set Reward Distribution"
      }
    ]);
    setCurrent(1);
    setError(false);
    setOpen(true);
    try {
      const tx = await rewardContract?.set(pid);
      const rescipt = await tx?.wait();
      setCurrent(2);
      cb(rescipt);
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  }, [cb, pid, rewardContract]);

  const collectFees = useCallback(async () => {
    setSteps([
      {
        label: "Initiate Buy Back"
      }
    ]);
    setCurrent(1);
    setError(false);
    setOpen(true);
    try {
      const tx = await bankingNodeContract?.collectFees();
      const rescipt = await tx?.wait();
      setCurrent(2);
      cb(rescipt);
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  }, [bankingNodeContract, cb]);

  const depositAndStake = useCallback(
    async (amount: string) => {
      setSteps([
        {
          label: "Approve " + baseTokenInfo?.label
        },
        {
          label: "Add Liquidity"
        },
        {
          label: "Approve pUSD"
        },
        {
          label: "Stake pUSD"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);
      try {
        let _amount = parseUnits(amount, baseTokenInfo?.decimals).toString();
        await approve(baseTokenContract, bankNodeAddress, _amount, account);
        setCurrent(2);
        let tx = await bankingNodeContract?.deposit(_amount);
        await tx?.wait();
        setCurrent(3);

        _amount = await getBaseTokenBalance();

        await approve(bankingNodeContract, config.REWARD, _amount, account);
        setCurrent(4);
        tx = await rewardContract?.deposit(pid, _amount);
        const rescipt = await tx?.wait();
        setCurrent(5);
        cb(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [
      baseTokenInfo?.label,
      baseTokenInfo?.decimals,
      baseTokenContract,
      bankNodeAddress,
      account,
      bankingNodeContract,
      getBaseTokenBalance,
      rewardContract,
      pid,
      cb
    ]
  );

  const stakeLpToken = useCallback(async () => {
    setSteps([
      {
        label: "Approve pUSD"
      },
      {
        label: "Stake pUSD"
      }
    ]);
    setCurrent(1);
    setError(false);
    setOpen(true);

    try {
      const amount = await getBaseTokenBalance();

      await approve(bankingNodeContract, config.REWARD, amount, account);

      setCurrent(2);
      const tx2 = await rewardContract?.deposit(pid, amount);
      const rescipt = await tx2?.wait();
      setCurrent(3);

      cb(rescipt);
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  }, [account, bankingNodeContract, cb, getBaseTokenBalance, pid, rewardContract]);

  const unstakeLpToken = useCallback(
    async (amount: string) => {
      setSteps([
        {
          label: "Unstake pUSD"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);

      try {
        const _amount = parseUnits(amount, 18).toString();

        const tx = await rewardContract?.withdraw(pid, _amount);
        const rescipt = await tx?.wait();
        setCurrent(2);

        cb(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [cb, pid, rewardContract]
  );

  const withdraw = useCallback(
    async (amount: string) => {
      setSteps([
        {
          label: "Withdraw " + baseTokenInfo?.label
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);

      try {
        const _amount = parseUnits(amount, baseTokenInfo?.decimals).toString();

        // const baseBalance = await getBaseTokenBalance();
        // console.log("balanceOf:", baseBalance.toString());

        const getBase = await bankingNodeContract?.getBaseTokenBalance(account!);
        console.log("getBaseTokenBalance:", getBase?.toString());

        console.log("witdraw amount:", _amount);
        const tx = await bankingNodeContract?.withdraw(_amount);
        const rescipt = await tx?.wait();
        setCurrent(2);
        cb(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [account, bankingNodeContract, baseTokenInfo?.decimals, baseTokenInfo?.label, cb]
  );

  const stake = useCallback(
    async (amount: string) => {
      setSteps([
        {
          label: "Approve"
        },
        {
          label: "Stake BNPL"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);

      try {
        const _amount = parseUnits(amount, 18).toString();

        await approve(bnplContract, bankNodeAddress, _amount, account);
        setCurrent(2);

        const tx = await bankingNodeContract?.stake(_amount);
        const rescipt = await tx?.wait();
        setCurrent(3);
        cb(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [account, bankNodeAddress, bankingNodeContract, bnplContract, cb]
  );

  const initiateUnstake = useCallback(
    async (amount: string) => {
      setSteps([
        {
          label: "Unstake BNPL"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);

      try {
        const totalStakingShares = await bankingNodeContract?.totalStakingShares();
        const stakedBNPL = await bankingNodeContract?.getStakedBNPL();

        const bnplAmount = new BigNumber(totalStakingShares?.toString() || 1)
          .dividedBy(stakedBNPL?.toString() || 1)
          .multipliedBy(new BigNumber(amount))
          .toPrecision(18)
          .toString();

        const _amount = parseUnits(bnplAmount, 18);
        const tx = await bankingNodeContract?.initiateUnstake(_amount);
        const rescipt = await tx?.wait();
        setCurrent(2);
        cb(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [bankingNodeContract, cb]
  );

  const unstake = useCallback(async () => {
    setSteps([
      {
        label: "Withdraw Unbonding Balance"
      }
    ]);
    setCurrent(1);
    setError(false);
    setOpen(true);

    try {
      const tx = await bankingNodeContract?.unstake();
      const rescipt = await tx?.wait();
      setCurrent(2);
      cb(rescipt);
    } catch (error) {
      setError(true);
      console.log("error:", error);
    }
  }, [bankingNodeContract, cb]);

  const reset = useCallback(async () => {
    setCurrent(0);
    setError(false);
    setSteps([]);
  }, []);

  const txDialog = useMemo(() => {
    return (
      <Dialog disableClose open={open} onClose={() => setOpen(false)} onExited={reset}>
        <Stepper current={current} error={error} items={steps} />
      </Dialog>
    );
  }, [current, error, open, reset, steps]);

  return {
    setRewardDistribution,
    depositAndStake,
    withdraw,
    stake,
    claimBNPL,
    stakeLpToken,
    unstakeLpToken,
    initiateUnstake,
    collectFees,
    unstake,
    txDialog
  };
};
