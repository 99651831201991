import clsx from "clsx";
import { forwardRef, ReactNode } from "react";

interface Props {
  className?: string;
  defaultClass?: string;
  children?: ReactNode;
}

const CardRender: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { className = "", defaultClass = "rounded-xl my-2 p-4 sm:p-6", children },
  ref
) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "bg-gray-50 border border-edge shadow-md dark:bg-gray-900 dark:border-edge",
        className,
        defaultClass
      )}
    >
      {children}
    </div>
  );
};

export const Card = forwardRef(CardRender);
