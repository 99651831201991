import { ApplicantStatus } from "components/pages/kyc/ApplicantStatus";
import { KYCStatus } from "components/pages/kyc/KYCStatus";
import { Button } from "components/ui/Button/Button";
import { Checkbox } from "components/ui/Checkbox/Checkbox";
import { CopyClipboard } from "components/ui/CopyClipboard/CopyClipboard";
import { Table } from "components/ui/Table/Table";
import dayjs from "dayjs";
import { useKYC } from "hooks/useKYC";
import { cloneElement, isValidElement, useCallback, useMemo } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { Column } from "react-table";
import { ApplicantWithStatuses } from "services/bankNode/bankNode.types";
import { formatEtherscanLink, prettyTx } from "utils";
import { currentChainId } from "utils/wallet/connectors";

type Props = {
  applicants: ApplicantWithStatuses[];
  fetchData?: () => void;
  list: string[];
  setList: (val: string[]) => void;
  action?: React.ReactNode;
};

export const ApplicantsTable: React.FC<Props> = ({
  applicants,
  fetchData,
  list,
  setList,
  action
}) => {
  const { address } = useParams();

  const { approve, reject, txDialog } = useKYC(address || "", true, "", fetchData);

  const selectRow = useCallback(
    (user: string, checked: boolean) => {
      const prvList = [...list.filter((item) => item !== user)];
      if (checked) {
        prvList.push(user);
      }
      setList(prvList);
    },
    [list, setList]
  );

  const columns = useMemo<Column<ApplicantWithStatuses>[]>(
    () => [
      {
        Header: "Address",
        accessor: (row) => (
          <div className="flex items-center">
            {prettyTx(row.applicant.externalUserId)}
            <a
              className="ml-1 text-lg hover:text-primary-700"
              href={formatEtherscanLink("Account", [currentChainId, row.applicant.externalUserId])}
              rel="noreferrer"
              target="_blank"
            >
              <BiLinkExternal />
            </a>
            <CopyClipboard className="ml-1 text-lg" text={row.applicant.externalUserId} />
          </div>
        )
      },
      {
        Header: "Date",
        accessor: (row) => dayjs(row.applicant.createdAt).format("DD MMM, YYYY - HH:mm")
      },
      {
        Header: "Email",
        accessor: (row) => row.applicant.email || "-"
      },
      {
        Header: "Name",
        accessor: (row) => (
          <div className="flex items-center">
            <a
              className="mx-1 hover:text-primary-700 text-lg"
              href={`https://cockpit.sumsub.com/checkus#/applicant/${row.applicant.id}/basicInfo?clientId=${row.applicant.clientId}`}
              rel="noreferrer"
              target="_blank"
            >
              <BiLinkExternal />
            </a>

            {(row.applicant?.fixedInfo?.firstName || "") +
              "-" +
              (row.applicant?.fixedInfo?.lastName || "")}
          </div>
        )
      },
      {
        Header: "Sumsub Status",
        accessor: (row) => <ApplicantStatus reviewStatus={row.applicant.review.reviewStatus} />
      },
      {
        Header: "Address Status",
        disableSortBy: true,
        accessor: (row) => (
          <KYCStatus bankNodeAddress={address!} user={row.applicant.externalUserId} />
        )
      },
      {
        Header: "Select",
        disableSortBy: true,
        cellClass: "text-right",
        columnClass: "text-right",
        accessor: (row) => (
          <div className="flex justify-end">
            <Checkbox
              checked={!!list.find((item) => item === row.applicant.externalUserId)}
              onChange={(e) => selectRow(row.applicant.externalUserId, !!e.target.checked)}
            />
          </div>
        )
      }
    ],
    [address, list, selectRow]
  );

  return (
    <section className="my-4 flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <h4 className="tg-title-h3 text-title mb-4">Applicants</h4>
        <div className="flex items-center gap-2">
          {action}
          <Button disabled={list.length === 0} variant="success" onClick={() => approve(list)}>
            Approve ({list.length})
          </Button>
          <Button disabled={list.length === 0} variant="danger" onClick={() => reject(list)}>
            Reject ({list.length})
          </Button>
        </div>
      </div>
      <Table columns={columns} data={applicants} />

      {isValidElement(txDialog) && cloneElement(txDialog)}
    </section>
  );
};
