import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data) toast.error(error?.response?.data.message);

    return Promise.reject(error);
  }
);

export const setAddressHeader = (address: string) => {
  axios.defaults.headers.common["address"] = address;
};

export const setAuthorizationHeader = (address: string, message: string, signature: string) => {
  axios.defaults.headers.common["address"] = address;
  axios.defaults.headers.common["message"] = message;
  axios.defaults.headers.common["signature"] = signature;
};

export const removeAuthorizationHeader = () => {
  delete axios.defaults.headers.common["address"];
  delete axios.defaults.headers.common["message"];
  delete axios.defaults.headers.common["signature"];
};

export default axios;
