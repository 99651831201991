import BigNumber from "bignumber.js";
import { useRewardControllerContract } from "hooks/useContracts";
import { useEffect, useMemo, useState } from "react";

export const BNPL_DAYILY_REWARDS = 193600;

export const useBankingNodeReward = (pid: string) => {
  const rewardContract = useRewardControllerContract();
  const [rewardShare, setRewardShare] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [poolInfo, totalAllocPoint] = await Promise.all([
          rewardContract?.poolInfo(pid),
          rewardContract?.totalAllocPoint()
        ]);

        setRewardShare(
          new BigNumber(poolInfo?.allocPoint.toString() || 0)
            .multipliedBy(100)
            .div(totalAllocPoint?.toString() || 1)
            .toFixed(2)
        );
      } catch (error) {
        console.log("error:", error);
      }
    };
    if (pid && rewardContract) fetchData();
  }, [rewardContract, pid]);

  const perDayReward = useMemo(
    () => new BigNumber(rewardShare).multipliedBy(BNPL_DAYILY_REWARDS).dividedBy(100),
    [rewardShare]
  );

  return {
    rewardShare,
    perDayReward
  };
};
