import clsx from "clsx";
import { ReactNode } from "react";

export type BadgeVariant =
  | "default"
  | "success"
  | "danger"
  | "gray"
  | "yellow"
  | "indigo"
  | "purple"
  | "pink"
  | "transparent";

interface Props {
  variant?: BadgeVariant;
  className?: string;
  defaultClass?: string;
  children?: ReactNode;
  rounded?: boolean;
}

const badgeColors = {
  default: "bg-primary-700 text-gray-50 dark:bg-primary-700 dark:text-white",
  danger: "bg-red-700 text-gray-50",
  success: "bg-green-700 text-gray-50",
  gray: "dark:bg-black-450 dark:text-gray-50 border border-edge bg-secondary text-black-400",
  yellow: "bg-yellow-700 text-black-400 dark:bg-yellow-700 dark:text-black-400",
  indigo:
    "border border-edge bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900",
  purple: "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900",
  pink: "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900",
  transparent: "border border-edge text-title"
};

export const Badge: React.FC<Props> = ({
  className,
  defaultClass = "text-xs font-semibold px-2 py-[0.2rem]",
  variant = "default",
  children,
  rounded = true
}) => {
  return (
    <span
      className={clsx(
        defaultClass,
        badgeColors[variant],
        rounded ? "rounded-xl" : "rounded-lg",
        className
      )}
    >
      {children}
    </span>
  );
};
