import BigNumber from "bignumber.js";
import BankingNode_ABI from "contracts/abi/BankingNode.json";
import config from "contracts/config";
import type { BankingNode } from "contracts/types";
import { BigNumberish, Contract } from "ethers";
import useBlockNumber from "hooks/useBlockNumber";
import { useBNPLPrice } from "hooks/useBNPLPrice";
import { useBankingNodeContract, useRewardControllerContract } from "hooks/useContracts";
import useTokenContract from "hooks/useTokenContract";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { BankNodeService } from "services/bankNode/bankNode.service";
import { fromWei, isZero, parseBalance } from "utils";
import { compareAddress } from "utils/common";
import { infuraProvider } from "utils/infura";
import { multicall } from "utils/multicall";
import { tokens } from "utils/tokens";

import { useBankingNodeQuery } from "./useBankingNodeQuery";

const YEAR_SECONDS = 31536000;
export const MIN_BOND_AMOUNT = 1500000;

export const useBankingNodeData = (
  bankNodeAddress: string,
  account: string | null | undefined,
  operator: string,
  baseToken = config.USDT
) => {
  const [pid, setPid] = useState("");
  const [baseTokenNodeBalance, setBaseTokenNodeBalance] = useState("");

  const [balances, setBalances] = useState({
    bnplBalance: "",
    operatorBalance: "",
    baseTokenBalance: "",
    unbondingBalance: "",
    unbondingShares: "",
    unbondBlock: ""
  });
  const [fetcher, setFetcher] = useState(false);
  const [rewardData, setRewardData] = useState({
    apr: "",
    pendingBnpl: "",
    rewardBalance: "",
    rewardDebt: ""
  });
  const baseTokenContract = useTokenContract(baseToken);
  const bankingNodeContract = useBankingNodeContract(bankNodeAddress);
  const rewardContract = useRewardControllerContract();

  const { bnplPrice } = useBNPLPrice();
  const { data: blockNumber } = useBlockNumber();

  const baseTokenInfo = useMemo(
    () => tokens.find((token) => compareAddress(baseToken, token.value)),
    [baseToken]
  );

  const { data: values, refetch: refetchValues } = useBankingNodeQuery(
    bankNodeAddress,
    baseTokenInfo
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await baseTokenContract?.balanceOf(bankNodeAddress);
        setBaseTokenNodeBalance(parseBalance(data, baseTokenInfo?.decimals));
      } catch (error) {}
    };
    if (baseTokenContract) fetchData();
  }, [bankNodeAddress, baseTokenContract, fetcher, baseTokenInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await rewardContract?.getPid(bankNodeAddress);
        setPid(data?.toString() || "");
      } catch (error) {
        setPid("65535");
      }
    };
    if (rewardContract) fetchData();
  }, [values, rewardContract, pid, bankNodeAddress]);

  const noRewardPool = useMemo(() => {
    return pid === "65535";
  }, [pid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [aprValue, pendingBnpl, userInfo] = await Promise.all([
          rewardContract?.getBnplApr(pid),
          rewardContract?.pendingBnpl(pid, account!),
          rewardContract?.userInfo(pid, account!)
        ]);
        setRewardData({
          apr: aprValue?.toString() || "",
          pendingBnpl: fromWei(pendingBnpl),
          rewardBalance: fromWei(userInfo?.amount),
          rewardDebt: fromWei(userInfo?.rewardDebt)
        });
      } catch (error) {
        console.log("error1111:", error);
      }
    };
    if (!noRewardPool && pid && rewardContract && account) fetchData();
  }, [values, rewardContract, account, pid, noRewardPool, fetcher]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!bankingNodeContract) {
          return;
        }

        const calls = [
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("getBNPLBalance", [
              account!
            ]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("getBNPLBalance", data)[0]
          },
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("getBNPLBalance", [
              operator
            ]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("getBNPLBalance", data)[0]
          },
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("getBaseTokenBalance", [
              account!
            ]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("getBaseTokenBalance", data)[0]
          },
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("getUnbondingBalance", [
              account!
            ]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("getUnbondingBalance", data)[0]
          },
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("unbondingShares", [
              account!
            ]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("unbondingShares", data)[0]
          },
          {
            target: bankingNodeContract.address,
            callData: bankingNodeContract.interface.encodeFunctionData("unbondBlock", [account!]),
            decoder: (data: string) =>
              bankingNodeContract.interface.decodeFunctionResult("unbondBlock", data)[0]
          }
        ];

        const { returnData } = await multicall.callStatic.aggregate(
          calls.map((call) => ({
            target: call.target,
            callData: call.callData
          }))
        );

        const [
          bnplBalance,
          operatorBalance,
          baseTokenBalanceData,
          unbondingBalance,
          unbondingShares,
          unbondBlock
        ] = (returnData as string[]).map((data, i) => calls[i].decoder(data)) as BigNumberish[];

        let baseTokenBalance = new BigNumber(baseTokenBalanceData?.toString() || 0);
        if (baseTokenBalance?.gte(1)) {
          baseTokenBalance = baseTokenBalance.minus(1);
        }
        setBalances({
          bnplBalance: fromWei(bnplBalance),
          operatorBalance: fromWei(operatorBalance),
          baseTokenBalance: parseBalance(baseTokenBalance.toString(), baseTokenInfo?.decimals),
          unbondingBalance: fromWei(unbondingBalance),
          unbondingShares: fromWei(unbondingShares),
          unbondBlock: unbondBlock?.toString() || ""
        });
      } catch (error) {
        console.log("error2222:", error);
      }
    };
    if (bankingNodeContract && account) fetchData();
  }, [values, bankingNodeContract, account, fetcher, operator, baseTokenInfo]);

  const satlRatio = useMemo(() => {
    if (!values.stakedBNPL || !values.totalAssetValue || !bnplPrice) return "";
    if (new BigNumber(values.totalAssetValue).isZero()) return "NaN";
    return new BigNumber(values.stakedBNPL)
      .multipliedBy(bnplPrice)
      .div(values.totalAssetValue)
      .toFixed(2);
  }, [values, bnplPrice]);

  const availableLiquidity = useMemo(() => {
    if (!values.accountsReceiveable || !values.totalAssetValue) return "";

    return new BigNumber(values.totalAssetValue).minus(values.accountsReceiveable).toString();
  }, [values]);

  const isDeactive = useMemo(() => {
    if (!balances.operatorBalance) return false;

    return new BigNumber(balances.operatorBalance).lt(MIN_BOND_AMOUNT);
  }, [balances]);

  const rewardAPR = useMemo(() => {
    if (!rewardData.apr || !bnplPrice) return "";

    return new BigNumber(fromWei(rewardData.apr)).times(bnplPrice).times(100).toFixed(2);
  }, [bnplPrice, rewardData.apr]);

  const stakingAPY = useMemo(() => {
    if (!values.stakedBNPL || !values.totalStakingShares || !values.timeCreated) return "";

    const timePassed = (new Date().getTime() / 1000 - Number(values.timeCreated)).toFixed(2);

    return new BigNumber(values.stakedBNPL)
      .div(values.totalStakingShares)
      .minus(1)
      .multipliedBy(YEAR_SECONDS)
      .multipliedBy(100)
      .div(timePassed)
      .toFixed(8);
  }, [values]);

  const lendingAPY = useMemo(() => {
    if (
      !values.totalAssetValue ||
      !values.totalSupply ||
      !values.timeCreated ||
      isZero(values.totalSupply)
    )
      return "";

    const timePassed = (new Date().getTime() / 1000 - Number(values.timeCreated)).toFixed(2);

    return new BigNumber(values.totalAssetValue)
      .div(values.totalSupply)
      .minus(1)
      .multipliedBy(YEAR_SECONDS)
      .multipliedBy(100)
      .div(timePassed)
      .toFixed(4);
  }, [values]);

  const pUSDValue = useMemo(() => {
    if (!values.totalAssetValue || !values.totalSupply) return "";
    return new BigNumber(values.totalAssetValue).div(values.totalSupply).toFixed(4);
  }, [values]);

  const netAPR = useMemo(() => {
    if (!lendingAPY || !rewardAPR) return "";

    return new BigNumber(lendingAPY).plus(rewardAPR).toFixed(2);
  }, [lendingAPY, rewardAPR]);

  const poolTokenBalance = useMemo(() => {
    if (!balances.baseTokenBalance || !rewardData.rewardBalance) return "";
    const baseTokenUSDT = new BigNumber(balances.baseTokenBalance || 0)
      .dividedBy(pUSDValue || 1)
      .toString();
    return new BigNumber(baseTokenUSDT).plus(rewardData.rewardBalance).toFixed(2);
  }, [balances, rewardData, pUSDValue]);

  const withdrawTimer = useMemo(() => {
    if (!balances.unbondingShares || !balances.unbondBlock || !blockNumber) return "";
    if (new BigNumber(balances.unbondingShares).isZero()) return "";

    return new BigNumber(balances.unbondBlock)
      .plus(46523)
      .minus(blockNumber)
      .multipliedBy(13)
      .toFixed(2);
  }, [balances, blockNumber]);

  const refetch = useCallback(() => {
    setFetcher(!fetcher);
    refetchValues();
  }, [fetcher, refetchValues]);

  const isOwner = useMemo(() => compareAddress(operator, account), [account, operator]);

  return {
    ...values,
    ...balances,
    ...rewardData,
    baseTokenInfo,
    pid,
    bankingNodeContract,
    satlRatio,
    availableLiquidity,
    currentAPR: stakingAPY,
    aggregateAPY: stakingAPY,
    stakingAPY,
    lendingAPY,
    rewardAPR,
    netAPR,
    pUSDValue,
    poolTokenBalance,
    withdrawTimer,
    isDeactive,
    noRewardPool,
    baseTokenNodeBalance,
    isOwner,
    refetch
  };
};

export const useGetBankinNodeList = () => {
  const { isLoading, error, data } = useQuery(
    "bank-nodes",
    async () => {
      try {
        const { data } = await BankNodeService.getBankNodes();
        return data;
      } catch (error) {}
    },
    {
      staleTime: 10 * 60000
    }
  );

  return {
    data: data || [],
    error,
    isLoading
  };
};

const fetchBankNodeStatus = async (bankNodeAddress: string, operator: string) => {
  try {
    const bankingNodeContract = new Contract(
      bankNodeAddress,
      BankingNode_ABI,
      infuraProvider
    ) as BankingNode;
    const operatorBalance = await bankingNodeContract?.getBNPLBalance(operator);

    const isDeactive = new BigNumber(fromWei(operatorBalance)).lt(MIN_BOND_AMOUNT);

    return isDeactive;
  } catch (error) {
    console.log("error fetchBankNodeStatus", error);
  }
};
export const useGetActiveBankinNodeList = () => {
  const { isLoading, error, data } = useQuery(
    "active-bank-nodes",
    async () => {
      try {
        const { data } = await BankNodeService.getBankNodes();
        const list = await Promise.all(
          data.map((item) => fetchBankNodeStatus(item.bankNodeAddress, item.ownerAddress))
        );
        return data.filter((_node, i) => !list[i]);
      } catch (error) {}
    },
    {
      staleTime: 10 * 60000
    }
  );

  return {
    data: data || [],
    error,
    isLoading
  };
};
