import { useWeb3React } from "@web3-react/core";
import clsx from "clsx";
import { Button } from "components/ui/Button/Button";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { currentChainId } from "utils/wallet/connectors";

import { Badge } from "../Badge/Badge";

interface Props {
  className?: string;
}
export const Login: React.FC<Props> = ({ className }) => {
  const { isLoggedIn, login } = useAuth();
  const { active, chainId } = useWeb3React();

  if (!active || currentChainId !== chainId) return null;

  if (isLoggedIn) return <Badge className={clsx("mr-4", className)}>Logged In</Badge>;

  return (
    <Button
      className={clsx("mr-2", className)}
      size="sm"
      style={{ height: "3rem" }}
      onClick={login}
    >
      Sign in
    </Button>
  );
};
