import React, { ImgHTMLAttributes } from "react";

export const Image: React.FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <img
    {...props}
    alt={props.alt}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = "/images/no-image.svg";
    }}
  />
);
