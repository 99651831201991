import clsx from "clsx";
import { useEffect, useState } from "react";
import { BiArrowFromBottom } from "react-icons/bi";

export const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className="fixed bottom-2 right-2">
      <button
        className={clsx(
          isVisible ? "opacity-100" : "opacity-0",
          "bg-primary-700 hover:bg-primary-600 focus:ring-primary-700 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2"
        )}
        type="button"
        onClick={scrollToTop}
      >
        <BiArrowFromBottom aria-hidden="true" className="h-6 w-6" />
      </button>
    </div>
  );
};
