import clsx from "clsx";
import { socials } from "configs/socials";
import { cloneElement, isValidElement } from "react";

interface Props {
  className?: string;
  itemClassName?: string;
  iconClassName?: string;
}

export const Socials: React.FC<Props> = ({
  className,
  itemClassName,
  iconClassName = "text-lg lg:text-2xl hover:text-primary-700 transition-colors"
}) => {
  return (
    <ul className={clsx("flex relative space-x-4 lg:space-x-6", className)}>
      {socials.map((social) => (
        <li key={social.name} className={itemClassName}>
          <a href={social.link} rel="noreferrer" target="_blank">
            {isValidElement(social.icon) && cloneElement(social.icon, { className: iconClassName })}
          </a>
        </li>
      ))}
    </ul>
  );
};
