import BigNumber from "bignumber.js";
import BankingNode_ABI from "contracts/abi/BankingNode.json";
import type { BankingNode } from "contracts/types";
import { BigNumberish, Contract } from "ethers";
import { useQueries, useQuery } from "react-query";
import { IBankNode } from "services/bankNode/bankNode.types";
import { fromWei, isZero, parseBalance } from "utils";
import { infuraProvider } from "utils/infura";
import { multicall } from "utils/multicall";
import { getToken, Token } from "utils/tokens";

const fetchBankNodeInfo = async (bankNodeAddress: string, baseTokenInfo: Token | undefined) => {
  try {
    const bankingNodeContract = new Contract(
      bankNodeAddress,
      BankingNode_ABI,
      infuraProvider
    ) as BankingNode;

    const calls = [
      {
        target: bankingNodeContract.address,
        callData: bankingNodeContract.interface.encodeFunctionData("getStakedBNPL"),
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("getStakedBNPL", data)[0]
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("getTotalAssetValue", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("getTotalAssetValue")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("accountsReceiveable", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("accountsReceiveable")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("totalSupply", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("totalSupply")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("getCurrentLoansCount", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("getCurrentLoansCount")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("slashingBalance", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("slashingBalance")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("timeCreated", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("timeCreated")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("totalStakingShares", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("totalStakingShares")
      },

      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("requireKYC", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("requireKYC")
      },
      {
        target: bankingNodeContract.address,
        decoder: (data: string) =>
          bankingNodeContract.interface.decodeFunctionResult("gracePeriod", data)[0],
        callData: bankingNodeContract.interface.encodeFunctionData("gracePeriod")
      }
    ];

    const { returnData } = await multicall.callStatic.aggregate(
      calls.map((call) => ({
        target: call.target,
        callData: call.callData
      }))
    );

    const [
      stakedBNPL,
      totalAssetValue,
      accountsReceiveable,
      totalSupply,
      loansCount,
      slashingBalance,
      timeCreated,
      totalStakingShares,
      requireKYC,
      gracePeriod
    ] = (returnData as string[]).map((data, i) => calls[i].decoder(data)) as BigNumberish[];

    const parsedTotalAssetValue = parseBalance(totalAssetValue, baseTokenInfo?.decimals);
    const parsedAccountsReceiveable = parseBalance(accountsReceiveable, baseTokenInfo?.decimals);

    const loanUtilization = new BigNumber(parsedAccountsReceiveable)
      .div(isZero(parsedTotalAssetValue) ? 1 : parsedTotalAssetValue)
      .multipliedBy(100)
      .toFixed(2);

    return {
      stakedBNPL: fromWei(stakedBNPL),
      totalAssetValue: parsedTotalAssetValue,
      accountsReceiveable: parsedAccountsReceiveable,
      totalSupply: fromWei(totalSupply),
      activeLoansCount: loansCount?.toString() || "",
      slashingBalance: fromWei(slashingBalance),
      timeCreated: timeCreated?.toString() || "",
      totalStakingShares: fromWei(totalStakingShares?.toString()) || "",
      requireKYC: !!requireKYC,
      gracePeriod: new BigNumber(gracePeriod?.toString() || 0).dividedBy(24 * 3600).toNumber(),
      availableLiquidity: new BigNumber(parsedTotalAssetValue)
        .minus(parsedAccountsReceiveable)
        .toString(),
      loanUtilization,
      bankNodeAddress
    };
  } catch (error) {
    console.log("error", error);
  }
};

const defaultBankNodeData = {
  stakedBNPL: "",
  totalAssetValue: "",
  accountsReceiveable: "",
  totalSupply: "",
  activeLoansCount: "",
  slashingBalance: "",
  timeCreated: "",
  totalStakingShares: "",
  loanUtilization: "",
  gracePeriod: 0,
  requireKYC: false
};

const getBankNodeQueryKey = (bankNodeAddress: string) => ["bank-node", bankNodeAddress];
export const useBankingNodeQuery = (bankNodeAddress: string, baseTokenInfo: Token | undefined) => {
  const { data, isLoading, refetch } = useQuery(
    getBankNodeQueryKey(bankNodeAddress),
    () => fetchBankNodeInfo(bankNodeAddress, baseTokenInfo),
    {
      staleTime: 60000
    }
  );

  return {
    data: data || defaultBankNodeData,
    isLoading,
    refetch
  };
};

export const useBankingNodesQuery = (bankNodes: IBankNode[]) => {
  return useQueries(
    bankNodes.map((node) => ({
      queryKey: getBankNodeQueryKey(node.bankNodeAddress),
      queryFn: () => fetchBankNodeInfo(node.bankNodeAddress, getToken(node.baseToken)),
      staleTime: 60000
    }))
  );
};
