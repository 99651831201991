import { Loader } from "components/Common/Loader/Loader";
import { AdminBankNode } from "components/pages/admin/AdminBankNode";
import { useGetBankinNodeList } from "hooks/web3/useBankingNodeData";

const Admin: React.FC = () => {
  const { data: nodes, isLoading } = useGetBankinNodeList();

  return (
    <div className="grid grid-cols-2 gap-4">
      {isLoading && <Loader fullScreen overlay />}

      {nodes?.map((node) => (
        <AdminBankNode key={node._id} node={node} />
      ))}
    </div>
  );
};

export default Admin;
