import Tippy from "@tippyjs/react";
import clsx from "clsx";
import uniqueId from "lodash/uniqueId";
import { cloneElement, isValidElement, useEffect, useState } from "react";

export interface FieldAttributesProps {
  label?: string | React.ReactNode;
  error?: string | false;
  className?: string;
  tooltip?: string | React.ReactNode;
  onLabelClick?: () => void;
}

export const FieldAttributes: React.FC<FieldAttributesProps> = ({
  label,
  error,
  className,
  tooltip,
  children,
  onLabelClick
}) => {
  const [id, setId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setId(uniqueId("id-"));
  }, []);

  const Label = (
    <label
      aria-hidden="true"
      className="mb-2 tg-body-bold text-text select-none self-start"
      htmlFor={id}
      onClick={() => onLabelClick?.()}
    >
      {label}
    </label>
  );

  return (
    <div className={clsx("flex flex-col w-full relative mb-5", className)}>
      {label && (tooltip ? <Tippy content={tooltip}>{Label}</Tippy> : Label)}

      {label ? isValidElement(children) && cloneElement(children, { id }) : children}

      {error && (
        <small className="tg-caption-sm text-red-700 ml-4 mt-0.5 absolute top-full">{error}</small>
      )}
    </div>
  );
};
