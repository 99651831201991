import clsx from "clsx";
import { InputHTMLAttributes, TextareaHTMLAttributes } from "react";

import { FieldAttributes, FieldAttributesProps } from "../FieldAttributes/FieldAttributes";

interface BaseProps {
  fieldAttributesProps?: Omit<FieldAttributesProps, "onLabelClick">;
  appendElem?: React.ReactNode;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseProps {
  multiline?: false;
}

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, BaseProps {
  multiline: true;
}

export type TextFieldProps = InputProps | TextAreaProps;

export const TextField: React.FC<TextFieldProps> = ({
  className,
  multiline = false,
  fieldAttributesProps,
  appendElem,
  ...props
}) => {
  const hasError = !!fieldAttributesProps?.error;
  const classes = clsx(
    `border border-solid bg-white dark:bg-gray-900 dark:placeholder-gray-400 outline-none rounded-lg px-5 w-full text-sm`,
    {
      "py-3": multiline,
      "h-12": !multiline,
      "cursor-not-allowed": props.disabled,
      "border-edge focus:border-primary-700 dark:text-white dark:focus:border-primary-500":
        !hasError,
      "border-red-700 focus:border-red-700 text-red-700 dark:text-red-700": hasError
    },
    className
  );

  return (
    <FieldAttributes {...fieldAttributesProps}>
      <div className="relative flex items-center">
        {multiline ? (
          <textarea className={classes} rows={4} {...(props as TextAreaProps)} />
        ) : (
          <input className={classes} {...(props as InputProps)} />
        )}
        {appendElem}
      </div>
    </FieldAttributes>
  );
};
