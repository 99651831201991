import { useWeb3React } from "@web3-react/core";
import { Badge } from "components/Common/Badge/Badge";
import { Card } from "components/Common/Card/Card";
import { NotificationItem } from "components/pages/notification/NotificationItem";
import { useOnClickOutside } from "hooks/common/useOnClickOutside";
import { useNewMessageCount, useNodeNewMessageCount } from "hooks/useNewMessageCount";
import { useNotification } from "hooks/useNotification";
import { useMemo, useRef, useState } from "react";
import { AiFillBell } from "react-icons/ai";
import { Link } from "react-router-dom";
import { compareAddress } from "utils/common";

import { ChatNotificationItem } from "./ChatNotificationItem";

export const NotificationMenu: React.FC = () => {
  const ref = useRef<HTMLLIElement>(null);
  const { account } = useWeb3React();
  const { data, isLoading } = useNotification();
  const { data: newMessages } = useNewMessageCount();
  const { data: newNodeMessages } = useNodeNewMessageCount();
  const [isOpen, setIsOpen] = useState(false);

  const list = useMemo(() => data.filter((item) => !item.read), [data]);
  const count = list.length + newMessages.length + newNodeMessages.length;
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <li ref={ref} className="relative flex items-center">
      <AiFillBell className="cursor-pointer text-title" onClick={() => setIsOpen(!isOpen)} />

      {count > 0 && (
        <Badge
          className="absolute -right-3 -top-3"
          defaultClass="animate-bounce text-xs font-semibold rounded px-1"
          variant="danger"
        >
          {count}
        </Badge>
      )}

      {isOpen && (
        <Card className="flex flex-col gap-2 mb-4 absolute top-full right-0 w-96 select-none">
          {newMessages.map((item) => (
            <ChatNotificationItem
              key={item.user}
              count={item.count}
              user={item.user}
              onClose={() => setIsOpen(false)}
            />
          ))}
          {newNodeMessages.map((item) => (
            <ChatNotificationItem
              key={item.user}
              bankingNode
              count={item.count}
              user={item.user}
              onClose={() => setIsOpen(false)}
            />
          ))}
          {list.slice(0, 4).map((notif) => (
            <NotificationItem
              key={notif._id}
              short
              bankNotif={!compareAddress(notif.receiver, account)}
              notification={notif}
            />
          ))}
          {list.length === 0 && !isLoading && (
            <div className="text-center mt-2">
              <span className="p-4 tg-caption lg:tg-content opacity-75">No New Notification</span>
            </div>
          )}
          <div className="text-center mt-2">
            <Link
              className="hover:text-primary-700 transition-colors tg-caption-bold lg:tg-content"
              to="/notification"
              onClick={() => setIsOpen(false)}
            >
              View Notification History
            </Link>
          </div>
        </Card>
      )}
    </li>
  );
};
