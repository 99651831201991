import { Stepper, StepperItem } from "components/Common/Stepper";
import { Dialog } from "components/ui/Dialog/Dialog";
import { ContractReceipt } from "ethers";
import { useBankingNodeContract } from "hooks/useContracts";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { compareAddress } from "utils/common";

export const useKYC = (
  bankNodeAddress: string,
  requireKYC?: boolean,
  owner = "",
  cb?: (rescipt: ContractReceipt | undefined) => void
) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState<StepperItem[]>([]);

  const bankingNodeContract = useBankingNodeContract(bankNodeAddress);

  const checkKYC = useCallback(
    async (account: string, redirect = true) => {
      try {
        let kyc = requireKYC;
        if (requireKYC === undefined) {
          kyc = await bankingNodeContract?.requireKYC();
        }
        if (!kyc || (account && compareAddress(owner, account))) return true;
        const isWhitelisted = await bankingNodeContract?.whitelistedAddresses(account!);
        if (!isWhitelisted && redirect) {
          navigate("/kyc/" + bankNodeAddress);
        }
        return !!isWhitelisted;
      } catch (error) {
        return false;
      }
    },
    [bankNodeAddress, bankingNodeContract, navigate, owner, requireKYC]
  );

  const approve = useCallback(
    async (accounts: string[]) => {
      setSteps([
        {
          label: "Whitelist"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);
      console.log("accounts:", accounts);

      try {
        const tx = await bankingNodeContract?.whitelistAddresses(accounts, true);
        const rescipt = await tx?.wait();
        setCurrent(2);

        cb?.(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [bankingNodeContract, cb]
  );

  const reject = useCallback(
    async (accounts: string[]) => {
      setSteps([
        {
          label: "Reject"
        }
      ]);
      setCurrent(1);
      setError(false);
      setOpen(true);

      try {
        const tx = await bankingNodeContract?.whitelistAddresses(accounts, false);
        const rescipt = await tx?.wait();
        setCurrent(2);

        cb?.(rescipt);
      } catch (error) {
        setError(true);
        console.log("error:", error);
      }
    },
    [bankingNodeContract, cb]
  );

  const reset = useCallback(async () => {
    setCurrent(0);
    setError(false);
    setSteps([]);
  }, []);

  const txDialog = useMemo(() => {
    return (
      <Dialog disableClose open={open} onClose={() => setOpen(false)} onExited={reset}>
        <Stepper current={current} error={error} items={steps} />
      </Dialog>
    );
  }, [current, error, open, reset, steps]);

  return {
    approve,
    reject,
    checkKYC,
    txDialog
  };
};
