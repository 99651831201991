/* eslint-disable react/no-unescaped-entities */
import { useWeb3React } from "@web3-react/core";
import { Badge } from "components/Common/Badge/Badge";
import { Footer } from "components/Layout/Footer/Footer";
import { Header } from "components/Layout/Header/Header";
import { ScrollToTop } from "components/ui/ScrollToTop/ScrollToTop";
import { useMemo } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useLocation } from "react-router-dom";
import { isMainnet } from "utils/common";
import { currentChainId } from "utils/wallet/connectors";

export const DefaultLayout: React.FC = () => {
  const { library, chainId } = useWeb3React();
  const wrongNetwork = useMemo(() => chainId !== currentChainId, [chainId]);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            backgroundColor: "var(--card-bg)",
            color: "var(--card-text)"
          }
        }}
      />
      <div className="flex flex-col h-screen">
        <Header wrongNetwork={wrongNetwork} />
        <main className="container flex-1 py-10">
          <LayoutContent chainId={chainId} library={library} wrongNetwork={wrongNetwork} />
        </main>
        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
};

interface LayoutContentProps {
  library: any;
  wrongNetwork: boolean;
  chainId: number | undefined;
}
const LayoutContent: React.FC<LayoutContentProps> = ({ library, wrongNetwork, chainId }) => {
  const location = useLocation();

  if (location.pathname === "/") return <Outlet />;

  if (!library)
    return (
      <div className="flex items-center justify-center">
        <Badge
          className="text-left my-20"
          defaultClass="text-xl font-semibold px-4 py-4 rounded inline-flex items-cetner justify-center"
          rounded={false}
          variant="yellow"
        >
          Please connect to a wallet to use the dapp
        </Badge>
      </div>
    );

  if (wrongNetwork)
    return (
      <div className="flex items-center justify-center">
        <Badge
          className="text-left my-20"
          defaultClass="text-center text-xl font-semibold p-4 rounded inline-flex items-cetner justify-center"
          variant="danger"
        >
          Wrong chainId: {chainId}
          <br />
          You're connected to an unsupported network. <br />
          Please connect to the Ethereum {isMainnet ? "Mainnet" : "Goerli Testnet"}.
        </Badge>
      </div>
    );

  return <Outlet />;
};
