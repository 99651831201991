import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

import { Spinner } from "../Spinner/Spinner";

export type ButtonSize = "xs" | "sm" | "md" | "lg";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  loading?: boolean;
  rounded?: boolean;
  variant?: "primary" | "secondary" | "success" | "danger" | "warning";
  mobileSize?: ButtonSize;
}

export const Button: React.FC<ButtonProps> = ({
  size = "md",
  mobileSize = size,
  rounded = false,
  loading = false,
  variant = "primary",
  disabled,
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(
        `flex items-center justify-center text-sm font-semibold leading-5 px-4 
         disabled:opacity-50 disabled:cursor-not-allowed dark:border dark:border-solid transition-colors ease-out group
         dark:disabled:text-white disabled:text-black-100`,
        {
          "rounded-md": !rounded,
          "rounded-4xl": rounded,
          //"dark:text-primary-700  dark:hover:text-primary-200 dark:hover:border-primary-200 dark:disabled:border-primary-700 dark:disabled:text-primary-700 dark:bg-transparent"
          "bg-primary-700 hover:bg-primary-200 hover:text-primary-700 disabled:bg-primary-700 text-white dark:border-primary-700":
            variant === "primary",
          //"dark:border-yellow-700 dark:hover:border-yellow-200 dark:disabled:border-yellow-700 dark:disabled:text-yellow-700 dark:bg-transparent"
          "bg-yellow-700 hover:bg-yellow-200 disabled:bg-yellow-700 text-black border-yellow-700":
            variant === "warning",
          "bg-green-700 hover:bg-green-200 hover:text-green-700 disabled:bg-green-700 dark:border-green-700 text-white":
            variant === "success",
          "bg-red-700 hover:bg-red-200 hover:text-red-700 disabled:bg-red-700 dark:border-red-700 text-white":
            variant === "danger",
          "bg-secondary hover:bg-edge dark:bg-black-450 dark:disabled:hover:bg-black-450 text-title border border-edge dark:hover:bg-gray-800":
            variant === "secondary",
          "md:min-w-button-lg md:h-14": size === "lg",
          "md:min-w-button-md md:h-12": size === "md",
          "md:min-w-button-sm md:h-11": size === "sm",
          "md:min-w-button-xs md:h-[30px]": size === "xs",
          "min-w-button-lg h-14": mobileSize === "lg",
          "min-w-button-md h-12": mobileSize === "md",
          "min-w-button-sm h-11": mobileSize === "sm",
          "min-w-button-xs h-[30px]": mobileSize === "xs"
        },
        className
      )}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <div className="flex justify-center items-center h-full">
          {loading && <Spinner className="mr-2 w-6 h-6" variant={variant} />}
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};
