import clsx from "clsx";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

export interface PaginationLocalization {
  page?: string;
  of?: string;
  nextPage?: string;
  prevPage?: string;
}

export interface PaginationProps {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (page: number) => void;
  localization?: PaginationLocalization;
}

export const Pagination: React.FC<PaginationProps> = ({
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageCount,
  localization = {
    page: "Page",
    of: "of",
    nextPage: "Next",
    prevPage: "Prev"
  }
}) => {
  if (pageCount < 2) return null;
  const buttonClasses =
    "inline-flex items-center py-2 px-4 text-sm font-medium border border-solid border-gray-300 disabled:opacity-60 transition-colors dark:bg-gray-900 dark:border-edge dark:text-title disabled:cursor-not-allowed";

  return (
    <div className="flex flex-col items-center mt-3">
      <p className="text-sm text-gray-700 dark:text-gray-300">
        {localization.page}{" "}
        <span className="font-semibold text-gray-900 dark:text-white">{pageIndex + 1}</span>{" "}
        {localization.of}{" "}
        <span className="font-semibold text-gray-900 dark:text-white">{pageCount}</span>
      </p>
      <div className="inline-flex mt-1">
        <button
          className={clsx(buttonClasses, "rounded-l", {
            "hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white": canPreviousPage
          })}
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <AiOutlineArrowLeft className="mr-1.5" />
          {localization.prevPage}
        </button>

        <button
          className={clsx(buttonClasses, "rounded-r border-l-0", {
            "hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white": canNextPage
          })}
          disabled={!canNextPage}
          onClick={nextPage}
        >
          {localization.nextPage}
          <AiOutlineArrowRight className="ml-1.5" />
        </button>
      </div>
    </div>
  );
};
