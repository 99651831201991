import Tippy from "@tippyjs/react";
import { useWeb3React } from "@web3-react/core";
import { Box } from "components/ui/Box/Box";
import { Button } from "components/ui/Button/Button";
import { Image } from "components/ui/Image/Image";
import { useBankingNode } from "hooks/useBankingNode";
import { useBankingNodeReward } from "hooks/useBankingNodeReward";
import { useBankingNodeData } from "hooks/web3/useBankingNodeData";
import React, { cloneElement, isValidElement, useCallback } from "react";
import { IBankNode } from "services/bankNode/bankNode.types";

type AdminBankNodeProps = {
  node: IBankNode;
};

export const AdminBankNode: React.FC<AdminBankNodeProps> = ({ node }) => {
  const { account } = useWeb3React();
  const { pid, refetch } = useBankingNodeData(
    node.bankNodeAddress,
    account,
    node.ownerAddress,
    node.baseToken
  );
  const { rewardShare } = useBankingNodeReward(pid);

  const refresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const { setRewardDistribution, txDialog } = useBankingNode(
    node.bankNodeAddress,
    pid,
    account,
    node.baseToken,
    refresh
  );

  return (
    <Box className="flex flex-col lg:flex-row items-center shadow-sm relative my-2">
      <div className="flex-1 flex flex-col lg:flex-row items-center justify-between p-4 lg:basis-7/12">
        <Tippy content={node.brief || node.title} placement="right">
          <div className="lg:w-1/4 flex items-center gap-6 mb-6 lg:mb-0">
            <Image
              alt={node.title}
              className="w-16 h-16 rounded-full object-cover bg-gray-900"
              src={node.logo}
            />
            <span className="tg-main-bold">{node.title}</span>
          </div>
        </Tippy>
        <div>Reward: {rewardShare}%</div>
        <Button onClick={setRewardDistribution}>Rebalance</Button>
      </div>

      {isValidElement(txDialog) && cloneElement(txDialog)}
    </Box>
  );
};
