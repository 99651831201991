import { Image } from "components/ui/Image/Image";
import { ReactNode } from "react";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import { FaMedium, FaTelegramPlane } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";

interface Social {
  name: string;
  icon: ReactNode;
  link: string;
}

export const socials: Readonly<Social[]> = [
  {
    name: "twitter",
    icon: <BsTwitter />,
    link: "https://twitter.com/bnplpay"
  },
  {
    name: "telegram",
    icon: <FaTelegramPlane />,
    link: "https://t.me/bnplpaychat"
  },
  {
    name: "discord",
    icon: <BsDiscord />,
    link: "https://discord.com/invite/zuXGDP5NWx"
  },
  {
    name: "medium",
    icon: <FaMedium className="text-xl" />,
    link: "https://medium.com/bnplpay"
  },
  {
    name: "facebook",
    icon: <ImFacebook2 />,
    link: "https://www.facebook.com/bnplpay"
  },

  {
    name: "defipulse",
    icon: (
      <span>
        <Image alt="defipulse" className="h-6" height={24} src="/images/defipulse.svg" />
      </span>
    ),
    link: "https://www.defipulse.com/"
  }
];
