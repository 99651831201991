import { setTheme } from "redux/theme/themeSlice";
import { Theme } from "types/theme";

import { useAppDispatch, useAppSelector } from "./redux";

export const useTheme = () => {
  const theme = useAppSelector((state) => state.theme.value);
  const dispatch = useAppDispatch();

  return {
    theme,
    isDark: theme === Theme.dark,
    setTheme: (theme: Theme) => dispatch(setTheme(theme)),
    toggleTheme: () => dispatch(setTheme())
  };
};
