import { useWeb3React } from "@web3-react/core";
import { setAuthorizationHeader } from "api/httpClient";
import { useCallback, useMemo } from "react";
import { setAuth, TOKEN_EXPIRY } from "redux/auth/authSlice";
import { compareAddress } from "utils/common";

import { useAppDispatch, useAppSelector } from "./common/redux";
import { useSignMessage } from "./useSignMessage";

export const useAuth = () => {
  const { account } = useWeb3React();
  const { address, signature, message } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { sign } = useSignMessage();

  const isLoggedIn = useMemo(() => {
    const difference = new Date().getTime() / 1000 - Number(message);
    return (
      address &&
      signature &&
      message &&
      compareAddress(address, account) &&
      difference < TOKEN_EXPIRY
    );
  }, [account, address, message, signature]);

  const login = useCallback(async () => {
    try {
      const res = await sign();
      setAuthorizationHeader(res.address, res.message, res.signature);
      dispatch(setAuth(res));
      return true;
    } catch (error) {
      console.log("error:", error);
      return false;
    }
  }, [dispatch, sign]);

  return { login, isLoggedIn };
};
