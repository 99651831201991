import "styles/index.scss";
import "./i18n";
import "tippy.js/dist/tippy.css";
import "rc-slider/assets/index.css";

import { Web3ReactProvider } from "@web3-react/core";
import ConfirmationModalContextProvider from "context/modalConfirmationContext.tsx ";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "redux/store";
import getLibrary from "utils/wallet/getLibrary";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ConfirmationModalContextProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </ConfirmationModalContextProvider>
        </Web3ReactProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
