import httpClient from "api/httpClient";

import { GetApplicantsResponse, GetKycAccessTokenResponse, IBankNode } from "./bankNode.types";

export class BankNodeService {
  private static prefix = "/bank-node";

  static getBankNodes() {
    return httpClient.get<IBankNode[]>(this.prefix);
  }

  static getBankNode(bankNodeAddress: string) {
    return httpClient.get<IBankNode>(this.prefix + "/" + bankNodeAddress);
  }

  static getBankNodeByTitle(title: string) {
    return httpClient.get<IBankNode | undefined>(this.prefix + "/title/" + title);
  }

  static createBankNode(payload: any) {
    return httpClient.post<any>(this.prefix, payload);
  }

  static editBankNode(bankNodeAddress: string, payload: any) {
    return httpClient.patch<any>(`${this.prefix}/${bankNodeAddress}`, payload);
  }

  static getKycAccessToken(bankNodeAddress: string) {
    return httpClient.get<GetKycAccessTokenResponse>(this.prefix + "/" + bankNodeAddress + "/kyc");
  }

  static getApplicants(bankNodeAddress: string) {
    return httpClient.get<GetApplicantsResponse>(
      this.prefix + "/" + bankNodeAddress + "/kyc/applicants"
    );
  }

  static getKYCInfo(bankNodeAddress: string) {
    return httpClient.get<{
      sumsubKey: string;
      sumsubToken: string;
    }>(this.prefix + "/" + bankNodeAddress + "/kyc/credentials");
  }

  static updateKYCInfo(bankNodeAddress: string, sumsubKey: string, sumsubToken: string) {
    return httpClient.post<GetApplicantsResponse>(
      this.prefix + "/" + bankNodeAddress + "/kyc/credentials",
      { sumsubKey, sumsubToken }
    );
  }
}
