import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";
import { setAddressHeader } from "api/httpClient";
import { Button } from "components/ui/Button/Button";
import { Dialog } from "components/ui/Dialog/Dialog";
import { Image } from "components/ui/Image/Image";
import useEagerConnect from "hooks/useEagerConnect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { prettyTx } from "utils";
import {
  currentChainId,
  injected,
  metamaskLink,
  switchNetwork,
  walletconnect,
  walletlink
} from "utils/wallet/connectors";

export const WalletConnect = () => {
  const { active, account, connector, activate, deactivate, chainId } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const [open, setOpen] = useState(false);

  useEagerConnect();

  const wrongNetwork = useMemo(() => account && chainId !== currentChainId, [chainId, account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) setActivatingConnector(undefined);
  }, [activatingConnector, connector]);

  useEffect(() => {
    setAddressHeader(account!);
  }, [account]);

  const connect = useCallback(
    async (connector: AbstractConnector) => {
      try {
        await activate(connector);
        setOpen(false);
      } catch (ex) {
        console.log("ex:", ex);
      }
    },
    [activate]
  );

  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {}
  }

  // reset loans in cache on account change
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.resetQueries("loans");
  }, [account, queryClient]);

  return (
    <>
      <Button mobileSize="sm" variant="warning" onClick={() => setOpen(true)}>
        {account && (
          <div className="max-w-60 engage-wallet w-full flex items-center px-4 justify-center truncate">
            <span>{prettyTx(account || "", 4, 4)}</span>
          </div>
        )}
        {!account && "Connect Wallet"}
      </Button>
      <Dialog className="dark:text-white" open={open} onClose={() => setOpen(false)}>
        <h1 className="text-center mb-1 tg-title-h6">Select a Wallet</h1>
        <p className="text-center mb-4">Please select a wallet to connect to this dapp</p>
        <div className="flex flex-col items-center justify-center gap-4">
          <Button
            size="lg"
            onClick={() => {
              if (!window.ethereum) {
                window.open(metamaskLink, "_blank", "noopener noreferrer");
              } else {
                switchNetwork();
                connect(injected);
              }
            }}
          >
            <Image alt="ledger" className="w-7 mr-2" src="/wallets/metamask.svg" />
            MetaMask
          </Button>
          <Button size="lg" onClick={() => connect(walletconnect)}>
            <Image alt="ledger" className="w-7 mr-2" src="/wallets/wc.png" />
            WalletConnect
          </Button>
          <Button size="lg" onClick={() => connect(walletlink)}>
            <Image
              alt="ledger"
              className="w-6 mr-2 rounded-full"
              src="/wallets/coinbase-wallet.jpeg"
            />
            Coinbase
          </Button>
          {active ? (
            !wrongNetwork ? (
              <>
                <span>
                  Connected with <b>{prettyTx(account || "", 8, 8)}</b>
                </span>
                <Button size="lg" variant="secondary" onClick={disconnect}>
                  Disconnect
                </Button>
              </>
            ) : (
              <span>Wrong Network</span>
            )
          ) : (
            <span>Not connected</span>
          )}
        </div>
      </Dialog>
    </>
  );
};
