/* eslint-disable react/jsx-key */
import clsx from "clsx";
import { Skeleton } from "components/Common/Skelton";
import { ReactElement, useMemo } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { Row, TableOptions, useFlexLayout, usePagination, useSortBy, useTable } from "react-table";

import { Pagination, PaginationLocalization } from "../Pagination/Pagination";

export interface TableProps<T extends Record<string, unknown>> extends TableOptions<T> {
  onClick?: (row: Row<T>) => void;
  paginationLocalization?: PaginationLocalization;
  isLoading?: boolean;
}

export const Table = <T extends Record<string, unknown>>(props: TableProps<T>): ReactElement => {
  const { onClick, paginationLocalization, isLoading, ...rest } = props;

  const defaultColumn = useMemo(
    () => ({
      width: 150,
      minWidth: 100
    }),
    []
  );

  const data = useMemo(
    () => (rest.data.length || !isLoading ? rest.data : Array(3).fill({})),
    [rest.data, isLoading]
  );

  const columns = useMemo(
    () =>
      rest.data.length || !isLoading
        ? rest.columns
        : rest.columns.map((column) => ({ ...column, Cell: <Skeleton /> })),
    [rest.data.length, rest.columns, isLoading]
  );

  const tableInstance = useTable(
    { ...rest, data, columns, defaultColumn },
    useSortBy,
    useFlexLayout,
    usePagination
  );

  const {
    headerGroups,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state,
    gotoPage,
    pageCount
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <>
      <div className="overflow-auto sm:rounded-lg border border-edge">
        <table {...getTableProps()} className="w-full">
          <thead className="border-b border-edge">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={clsx(
                      "py-6 px-5 text-sm font-semibold tracking-wider text-text flex items-center",
                      column.columnClass || "text-left"
                    )}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span className="inline-flex items-center">
                      {column.render("Header")}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <AiOutlineArrowUp className="text-xs ml-1" />
                        ) : (
                          <AiOutlineArrowDown className="text-xs ml-1" />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => onClick?.(row)}
                  {...row.getRowProps()}
                  className="border-b last:border-b-0 border-edge text-title"
                >
                  {row.cells.map((cell) => (
                    <td
                      className={clsx(
                        "py-6 px-5 tg-caption lg:tg-content flex items-center",
                        cell.column.cellClass || "text-left"
                      )}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
            {!page.length && !isLoading && (
              <tr className="text-center border-b last:border-b-0 border-edge">
                <td className="py-6 px-5 tg-caption lg:tg-content opacity-75">No Result</td>
              </tr>
            )}

            {/* {!page.length &&
              loading &&
              Array.from(Array(4), (_, i) => i + 1).map((i) => (
                <tr key={i} className="text-center border-b last:border-b-0 border-edge">
                  <td className="py-6 px-5 tg-caption lg:tg-content opacity-75">
                    <Skeleton className="h-6 block" />
                  </td>
                </tr>
              ))} */}
          </tbody>
        </table>
      </div>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        localization={paginationLocalization}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        previousPage={previousPage}
      />
    </>
  );
};
