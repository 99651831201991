import { useState } from "react";

export const usePaginate = (pageSize: number, listLength: number) => {
  const [activePage, setActivePage] = useState(0);
  const paginate = <T>(arr: T[]) => {
    const startIndex = activePage * pageSize;
    const endIndex =
      arr?.length && startIndex + pageSize > arr?.length ? arr?.length : startIndex + pageSize;
    return arr?.slice(startIndex, endIndex);
  };

  const pageCount = Math.ceil(listLength / pageSize);

  return {
    activePage,
    setActivePage,
    paginate,
    canNextPage: activePage < pageCount - 1,
    canPreviousPage: activePage > 0,
    gotoPage: setActivePage,
    nextPage: () => setActivePage(activePage + 1),
    pageCount,
    pageSize,
    previousPage: () => setActivePage(activePage - 1)
  };
};
