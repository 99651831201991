import jsCookie from "js-cookie";
import { Theme } from "types/theme";

export const cookies = {
  NEXT_LOCALE: "NEXT_LOCALE",
  theme: "theme",
  address: "address",
  signature: "signature",
  message: "message"
} as const;

export interface Cookies {
  NEXT_LOCALE: string;
  theme: Theme;
  address: string;
  signature: string;
  message: string;
}

export const getParsedCookies = () => jsCookie.get() as unknown as Cookies;
