import httpClient from "api/httpClient";

import { IMessage } from "./chat.types";

export class ChatService {
  private static prefix = "/chat";

  static getRooms(from: string) {
    return httpClient.get<string[]>(this.prefix + "/rooms/" + from);
  }

  static getNewMessageCount(account: string) {
    return httpClient.get<{ [key: string]: number }>(this.prefix + "/messages-count", {
      headers: {
        address: account
      }
    });
  }

  static getNodeNewMessageCount(account: string) {
    return httpClient.get<{ [key: string]: number }>(this.prefix + "/banking-node/messages-count", {
      headers: {
        address: account
      }
    });
  }

  static getMessages(address: string) {
    return httpClient.get<IMessage[]>(this.prefix + "/messages/" + address);
  }

  static getNodeMessages(address: string) {
    return httpClient.get<IMessage[]>(this.prefix + "/banking-node/messages/" + address);
  }
}
