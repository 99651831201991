import { SumsubLink } from "components/Common/SumsubLink";
import { Button } from "components/ui/Button/Button";
import { Checkbox } from "components/ui/Checkbox/Checkbox";
import { TextField } from "components/ui/TextField/TextField";

import { useForm } from "./KYCForm.hook";

interface Props {
  requireKYC: boolean;
  nodeAddress: string;
  sumsubKey: string;
  sumsubToken: string;
  refetch: () => void;
}

export const KYCForm: React.FC<Props> = ({
  refetch,
  nodeAddress,
  sumsubKey,
  sumsubToken,
  requireKYC
}) => {
  const { formik, errors, loading } = useForm(
    nodeAddress,
    sumsubKey,
    sumsubToken,
    requireKYC,
    refetch
  );

  return (
    <form className="flex flex-col" onSubmit={formik.handleSubmit}>
      <div className="mt-4">
        <TextField
          {...formik.getFieldProps("sumsubToken")}
          fieldAttributesProps={{
            ...errors.sumsubToken,
            label: (
              <div className="flex flex-wrap wrap">
                Sumsub Token{" "}
                <span className="text-green-700 text-sm">
                  (Please paste the full token including ‘sbx:’)
                </span>
              </div>
            )
          }}
          placeholder="Sumsub Token"
        />
        <TextField
          {...formik.getFieldProps("sumsubKey")}
          fieldAttributesProps={{
            ...errors.sumsubKey,
            label: "Sumsub Key"
          }}
          placeholder="Sumsub Key"
        />
      </div>
      <Checkbox
        {...formik.getFieldProps("requireKYC")}
        checked={formik.values.requireKYC}
        className="text-primary-700 tg-content-bold my-4"
        label="Require KYC"
      />
      <Button className="w-full max-w-xs my-4 mx-auto" loading={loading} type="submit">
        Submit
      </Button>
      <SumsubLink className="mx-auto" />
    </form>
  );
};
