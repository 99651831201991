import clsx from "clsx";
import { NotificationMenu } from "components/pages/notification/NotificationMenu";
import { headerRoutes } from "configs/headerRoutes";
import { useAuth } from "hooks/useAuth";
import { useGetBankNodeAddress } from "hooks/useGetBankNodeAddress";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Props {
  className?: string;
}

export const HeaderRoutes: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation("layouts");
  const { isLoggedIn } = useAuth();
  const { bankNodeAddress } = useGetBankNodeAddress();

  const getClass = (isActive: boolean) =>
    clsx("hover:text-primary-700 transition-colors flex items-center", {
      "text-primary-700 dark:text-primary-700": isActive,
      "text-black dark:text-gray-50": !isActive
    });
  return (
    <nav className={className}>
      <ul className="flex flex-col lg:flex-row space-y-4 lg:space-x-6 lg:space-y-0">
        {headerRoutes.map(({ path, title, external }) => (
          <li key={path} className="tg-content-bold relative text-title">
            {external ? (
              <a className={getClass(false)} href={path} rel="noreferrer" target="_blank">
                <span>{t(title)}</span>
              </a>
            ) : (
              <NavLink end className={({ isActive }) => getClass(isActive)} to={path}>
                <span>{t(title)}</span>
              </NavLink>
            )}
          </li>
        ))}

        {bankNodeAddress && (
          <li className="tg-content-bold relative text-title">
            <NavLink
              className={({ isActive }) => getClass(isActive)}
              to={"bank-nodes/" + bankNodeAddress}
            >
              <span>My Node</span>
            </NavLink>
          </li>
        )}
        {isLoggedIn && <NotificationMenu />}
      </ul>
    </nav>
  );
};
