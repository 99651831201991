import { useFormik } from "formik";
import { useBankingNodeContract } from "hooks/useContracts";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BankNodeService } from "services/bankNode/bankNode.service";
import * as yup from "yup";

export const useForm = (
  nodeAddress: string,
  sumsubKey: string,
  sumsubToken: string,
  requireKYC: boolean,
  refetch: () => void
) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    requireKYC: yup.bool(),
    sumsubToken: yup.string().when("requireKYC", {
      is: true,
      then: yup.string().required()
    }),
    sumsubKey: yup.string().when("requireKYC", {
      is: true,
      then: yup.string().required()
    })
  });
  const bankingNodeContract = useBankingNodeContract(nodeAddress);

  const formik = useFormik({
    initialValues: {
      requireKYC: requireKYC,
      sumsubToken: sumsubToken || "",
      sumsubKey: sumsubKey || ""
    },
    validationSchema,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        if (requireKYC !== form.requireKYC) {
          const tx = await bankingNodeContract?.setKYC(form.requireKYC);
          await tx?.wait();
        }
        await BankNodeService.updateKYCInfo(nodeAddress, form.sumsubKey, form.sumsubToken);
        toast.success("Successfully updated");
        refetch();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  });

  const errors = useMemo(
    () => ({
      sumsubToken: {
        error: formik.touched.sumsubToken && formik.errors.sumsubToken
      },
      sumsubKey: {
        error: formik.touched.sumsubKey && formik.errors.sumsubKey
      }
    }),

    [formik.touched, formik.errors]
  );

  return { formik, errors, loading };
};
