import type { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import clsx from "clsx";
import { Button } from "components/ui/Button/Button";
import { TextField } from "components/ui/TextField/TextField";
import { parseUnits } from "ethers/lib/utils";
import useSendToken from "hooks/useSendToken";
import useTokenBalance from "hooks/useTokenBalance";
import { useCallback, useState } from "react";
import { currency } from "utils/currency";
import { formatNumberToUnit, parseBalance } from "utils/index";

type TokenBalanceProps = {
  tokenAddress: string;
  symbol: string;
  summary?: boolean;
  className?: string;
  defaultClassName?: string;
};

const TokenBalance = ({
  tokenAddress,
  symbol,
  summary,
  className,
  defaultClassName = "mr-2 text-xs font-semibold"
}: TokenBalanceProps) => {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(100);
  const { account } = useWeb3React<Web3Provider>();
  const { data, decimals } = useTokenBalance(account!, tokenAddress);

  const { send } = useSendToken(tokenAddress);

  const submit = useCallback(() => {
    if (!address) return;
    send(address, parseUnits(amount.toString(), decimals));
  }, [address, send, amount, decimals]);

  if (summary) {
    return (
      <span className={clsx(className, defaultClassName)}>
        {formatNumberToUnit(parseBalance(data ?? 0, decimals), 2, 2) + ` ${symbol}`}
      </span>
    );
  }
  return (
    <div className={clsx("mb-2", className)}>
      <div className="flex items-center gap-x-4">
        <TextField
          fieldAttributesProps={{ label: "Receiver" }}
          placeholder="Receiver"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          fieldAttributesProps={{ label: "Amount" }}
          min="0"
          placeholder="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </div>
      <div className="flex item-center justify-between">
        <Button onClick={submit}>Send</Button>
        <span>Balance: {currency(parseBalance(data ?? 0, decimals)) + ` ${symbol}`}</span>
      </div>
    </div>
  );
};

export default TokenBalance;
