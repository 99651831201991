import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
  className?: string;
  children?: ReactNode;
  defaultClass?: string;
  variant?: "dashed";
}

export const Box: React.FC<Props> = ({
  className,
  defaultClass = "px-6 md:px-8 py-6 gap-2 relative",
  children,
  variant
}) => {
  return (
    <div
      className={clsx(
        "rounded-xl bg-gradient-to-r bg-white dark:bg-gray-900 border border-edge",
        defaultClass,
        className,
        {
          "border border-edge bg-[hsla(0,0%,100%,.5)]": variant === "dashed"
        }
      )}
    >
      {children}
    </div>
  );
};
