export const paths = {
  index: "/",
  bankNodes: {
    index: "/bank-nodes",
    create: "/bank-nodes/create",
    address: (address = ":address") => "/bank-nodes/" + address,
    edit: (address = ":address") => "/bank-nodes/" + address + "/edit"
  },
  borrow: {
    index: "/borrow",
    create: "/borrow/create",
    address: (id = ":id") => "/borrow/" + id,
    edit: (id = ":id") => "/borrow/" + id + "/edit"
  },
  kyc: {
    index: (address = ":address") => "/kyc/" + address,
    applicants: (address = ":address") => "/kyc/" + address + "/applicants"
  },
  farm: "/farm",
  myNode: "/my-node",
  docs: "/docs",
  buy: "/buy",
  termsAndConditions: "/terms-conditions",
  privacy: "/privacy-policy",
  admin: "/admin",
  notification: "/notification",
  chat: (id = ":id") => "/chat/" + id,
  chatUser: (user = ":user") => "/chat/user/" + user,
  chatNode: (node = ":node") => "/chat/node/" + node
} as const;
