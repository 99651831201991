import { Button } from "components/ui/Button/Button";

interface Props {
  className?: string;
}

export const SumsubLink: React.FC<Props> = ({ className }) => (
  <a
    className={className}
    href="https://cockpit.sumsub.com/checkus#/signup"
    rel="noreferrer"
    target="_blank"
  >
    <Button rounded size="xs" type="button">
      <span className="px-2">Sign Up to SumSub</span>
    </Button>
  </a>
);
