import { useWeb3React } from "@web3-react/core";
import { Notifications } from "components/pages/notification/Notifications";
import { useGetBankNodeAddress } from "hooks/useGetBankNodeAddress";
import { useNotification } from "hooks/useNotification";
import { useEffect, useMemo } from "react";
import { compareAddress } from "utils/common";

const Notification: React.FC = () => {
  const { account } = useWeb3React();
  const { data, isLoading, readAll } = useNotification();
  const { bankNodeAddress } = useGetBankNodeAddress();

  const userNotifications = useMemo(
    () => data.filter((item) => compareAddress(item.receiver, account)),
    [data, account]
  );
  const bankNotifications = useMemo(
    () => data.filter((item) => !compareAddress(item.receiver, account)),
    [data, account]
  );

  useEffect(() => {
    if (data.length && data.some((notif) => !notif.read)) {
      readAll();
    }
  }, [data, readAll]);

  return (
    <div className="flex flex-col gap-2">
      <Notifications isLoading={isLoading} list={userNotifications} />
      {bankNodeAddress && (
        <Notifications bankNotif isLoading={isLoading} list={bankNotifications} />
      )}
    </div>
  );
};

export default Notification;
