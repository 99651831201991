import { useState } from "react";

export const useBNPLPrice = () => {
  const [bnplPrice] = useState<string>("0.01300808");

  // useEffect(() => {
  //   getBnplUsdPrice().then((bnplPrice) => {
  //     setBnplPrice(`${bnplPrice}`);
  //   });
  // }, []);

  return { bnplPrice };
};
