/* eslint-disable react/no-unescaped-entities */

const TermsAndConditions: React.FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <p className="tg-title-h3 text-title">Terms of Service</p>
      <p className="tg-title-h6">Effective Date: July 20, 2022</p>
      <p>
        Welcome to https://bnplpay.co (the &ldquo;Website&rdquo;). This Terms of Use Agreement (the
        &ldquo;Agreement&rdquo;) is made and entered into by and between you (&ldquo;User&rdquo;)
        and BNPL Pay Foundation (the &ldquo;Company&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;,
        &ldquo;our&rdquo;, &ldquo;bnplpay.co&rdquo;, &ldquo;Interface&rdquo; ). This Agreement sets
        forth the terms and conditions that govern your use of and access to the Website and any
        products, materials, and services provided by or on the Website (collectively, the
        &ldquo;Services&rdquo;).
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Acceptance of this Agreement</strong>
      </p>
      <p>
        <strong className="tg-title-h6">
          1.1. Acceptance Through Using or Accessing the Services
        </strong>
      </p>
      <p>
        Please review the following terms carefully. By accessing or using the Services (or by
        clicking on &ldquo;accept&rdquo; or &ldquo;agree&rdquo; to this Agreement when prompted),
        you agree to be bound by the terms and conditions of this Agreement on behalf of yourself or
        the entity or organisation that you represent. If you do not agree to the terms and
        conditions of this Agreement, you may not use or access the Services and must exit the
        Website immediately.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">
          1.2. Eligibility Requirements to Use or Access the Services
        </strong>
      </p>
      <p>
        To use the Website or any other Services, you must be (i) at least 18 years old, and (ii)
        not a competitor of or using the Services for purposes that are competitive with the
        Company.
      </p>
      <p>
        By accessing or using the Services, you represent and warrant that you meet all the
        foregoing eligibility requirements. You also represent and warrant that you have the right,
        authority, and capacity to enter into this Agreement on your behalf or the entity or
        organisation that you represent. If you do not meet all these requirements, you may not use
        or access the Services.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">1.3. Changes to this Agreement</strong>
      </p>
      <p>
        The Company reserves the right to change this Agreement from time to time in its sole
        discretion without notice to you. The latest version of the Agreement will be posted on the
        Website and should be reviewed prior to accessing or using the Services. All changes will be
        effective immediately when posted on the Website and will apply to your use of and access to
        the Services from that point onward.
      </p>
      <p>
        Changes affecting crucial functionality that have a direct impact on the returns of Bank
        Node Operators, Lenders, Borrowers or Stakers will be communicated ahead of time, prior to
        implementation.
      </p>
      <p>
        Changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will
        not apply to any disputes for which the parties have actual notice before the date the
        change is posted on the Interface.
      </p>
      <p>
        The User&rsquo;s continued use of the Interface following the posting of revised Terms of
        Use means that the User accepts and agrees to the changes. The User is expected to check
        this page each time it accesses this Interface, so it is aware of any changes, as they are
        binding on the User.
      </p>
      <p>
        <br />
        <br />
        <br />
        <strong className="tg-title-h6">Access to the Services</strong>
      </p>
      <p>
        The Services may change from time to time as the Company evolves, refines, or adds more
        features to the Services. The Company reserves the right to modify, withdraw, or discontinue
        the Services, in whole or in part, at any time without notice to you. You agree that the
        Company shall have no liability to you or any third party for any losses or damages caused
        by the Services not being available, in whole or in part, at any time or for any period.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Policy for Using the Services</strong>
      </p>
      <p>
        <strong className="tg-title-h6">3.1. Prohibited Uses</strong>
      </p>
      <p>
        You may use the Services for lawful purposes only and in accordance with this Agreement. You
        agree not to use the Services in any way that could damage the Services or general business
        of the Company. You may use the Services for any business or commercial purposes.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Intellectual Property Rights</strong>
      </p>
      <p>
        <strong className="tg-title-h6">4.1. Ownership of Intellectual Property</strong>
      </p>
      <p>
        You acknowledge that all intellectual property rights, including copyrights, trademarks,
        trade secrets, and patents, in the Services and its contents, features, and functionality
        (collectively, the &ldquo;Content&rdquo;), are owned by the Company, its licensors, or other
        providers of such material. The Content is protected by U.S. and international intellectual
        property or proprietary rights laws. Neither this Agreement nor your access to the Services
        transfers to you any right, title, or interest in or to such intellectual property rights.
        Any rights not expressly granted in this Agreement are reserved by the Company and its
        licensors.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">4.2. Licence to Use the Services</strong>
      </p>
      <p>
        During the Term of this Agreement, the Company grants you a limited, non-exclusive,
        non-transferable, non-sublicensable, and revocable licence to use and access the Content for
        any business or commercial use in accordance with this Agreement. The Content may not be
        used for any other purpose. This licence will terminate upon your cessation of use of the
        Services or at the termination of this Agreement.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">4.3. Certain Restrictions</strong>
      </p>
      <p>The rights granted to you in this Agreement are subject to the following restrictions:</p>
      <p>
        No Copying or Distribution. You shall not copy, reproduce, publish, display, perform, post,
        transmit, or distribute any part of the Content in any form or by any means except as
        expressly permitted herein or as enabled by a feature, product, or the Services when
        provided to you.
      </p>
      <p>
        No Modifications. You shall not modify, create derivative works from, translate, adapt,
        disassemble, reverse compile, or reverse engineer any part of the Content.
      </p>
      <p>
        No Exploitation. You shall not sell, licence, sublicense, transfer, assign, rent, lease,
        loan, host, or otherwise exploit the Content or the Services in any way, whether in whole or
        in part.
      </p>
      <p>
        No Altering of Notices. You shall not delete or alter any copyright, trademark, or other
        proprietary rights notices from copies of the Content.
      </p>
      <p>
        No Competition. You shall not access or use the Content in order to build a similar or
        competitive website, product, or service.
      </p>
      <p>
        Systematic Retrieval. You shall not use any information retrieval system to create, compile,
        directly or indirectly, a database, compilation, collection or directory of the Content or
        other data from the Services.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">4.4. Trademark Notice</strong>
      </p>
      <p>
        All trademarks, logos, and service marks displayed on the Services are either the
        Company&rsquo;s property or the property of third parties. You may not use such trademarks,
        logos, or service marks without the prior written consent of their respective owners.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Assumption of Risk</strong>
      </p>
      <p>
        The information presented on or through the Services is made available for general
        information purposes only. The Company does not warrant the accuracy, completeness,
        suitability or quality of any such information. Any reliance on such information is strictly
        at your own risk. The Company disclaims all liability and responsibility arising from any
        reliance placed on such information by you or any other user to the Services, or by anyone
        who may be informed of any of its contents.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Privacy</strong>
      </p>
      <p>
        The Children&rsquo;s Online Privacy Protection Act requires that online service providers
        obtain parental consent before they knowingly collect personally identifiable information
        online from children who are under 13 years old. We do not knowingly collect or solicit
        personally identifiable information from children under 13 years old. If you are a child
        under 13 years old, please do not attempt to register for the Services or send any personal
        information about yourself to us. If we learn we have collected personal information from a
        child under 13 years old, we will delete that information as quickly as possible. If you
        believe that a child under 13 years old may have provided us personal information, please
        contact us.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Third-Party Links and Ads.</strong>
      </p>
      <p>
        The Services may contain links to third-party websites, resources, and services, as well as
        advertisements (collectively, &ldquo;Third-Party Links&rdquo;). Third-Party Links are
        provided for your convenience only. The Company does not review, approve, monitor, endorse,
        warrant, or make any representations with respect to Third-Party Links. The Company has no
        control over the contents, products, or services of any Third-Party Link and accepts no
        responsibility for them or for any loss or damage that may arise from your use of them. If
        you decide to access any Third-Party Link, you do so entirely at your own risk and subject
        to the terms and conditions of use for such Third-Party Link. You should make whatever
        investigation you feel necessary or appropriate before proceeding with any transaction in
        connection with any Third-Party Link.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Termination</strong>
      </p>
      <p>
        <strong className="tg-title-h6">8.1. Termination</strong>
      </p>
      <p>
        The Company may suspend or terminate your access or rights to use the Services at any time.
        Upon termination of your access or rights to use the Services, your right to access and use
        the Services will immediately cease. The Company will not have any liability whatsoever to
        you for any suspension or termination of your rights under this Agreement, including for
        termination of your account. If you have registered for an account, you may terminate this
        Agreement at any time by contacting the Company and requesting termination.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">8.2. Effect of Termination</strong>
      </p>
      <p>
        Upon termination of this Agreement, any provisions that by their nature should survive
        termination shall remain in full force and effect. This includes, without limitation,
        ownership of intellectual property provisions, warranty disclaimers, and limitations of
        liability. Termination of your access to and use of the Services shall not relieve you of
        any obligations arising or accruing prior to termination or limit any liability that you
        otherwise may have to the Company or any third party.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Limitation of Liability</strong>
      </p>
      <p>
        bnplpay.co provides information and resources about the fundamentals of the decentralised
        non-custodial liquidity protocol called the BNPL Pay (the &ldquo;BNPL Pay Protocol&rdquo; or
        &ldquo;Protocol&rdquo;). bnplpay.co is just one of the available access points to the BNPL
        Pay Protocol that is running on the decentralised Ethereum blockchain.
      </p>
      <p>
        bnplpay.co is provided as an informational resource. bnplpay.co provides resources about the
        fundamentals of the BNPL Pay Protocol, which is a fully decentralised, protocol deployed the
        Ethereum blockchain, and provides information about the wider BNPL Pay ecosystem,
        governance, community, and various interfaces and integrations to the BNPL Pay Protocol. All
        information provided in connection with your access and use of the Site and the Services is
        for informational purposes only. You should not take, or refrain from taking, any action
        based on any information contained on the Site or any other information that we make
        available at any time, including blog posts, data, articles, links to third-party content,
        discord content, news feeds, tutorials, tweets, and videos. Before you make any financial,
        legal, technical, or other decisions involving the Services, you should seek independent
        professional advice from a licensed and qualified individual in the area for which such
        advice would be appropriate. The Services provide, or third parties may provide, links to
        other sites, applications, or resources. You acknowledge and agree that we are not
        responsible for the availability of such external sites, applications or resources, and do
        not endorse and are not responsible or liable for any content, advertising, products, or
        other materials on or available from such sites or resources. You further acknowledge and
        agree that we will not be responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with use of or reliance on any such
        content, goods, or services available on or through any such site or resource. Because the
        Site provides information about the BNPL Pay Protocol, these Terms also provide some
        information about the use of the Protocol. This information is not intended to be
        comprehensive or address all aspects of the Protocol. There is additional documentation on
        the Site about the functioning of the Protocol or its ecosystem or community.
      </p>
      <p>
        It is important to understand that neither we nor any affiliated entity is a party to any
        transaction on the blockchain networks underlying the BNPL Pay Protocol; we do not have
        possession, custody or control over any crypto assets appearing on the Services; and we do
        not have possession, custody, or control over any user&rsquo;s funds. You understand that
        when you interact with any BNPL Pay Protocol smart contracts, you retain control over your
        crypto assets at all times subject to lending provision(s). The private key associated with
        the wallet address from which you transfer crypto assets or the private key associated is
        the only private key that can control the crypto assets you transfer into the smart
        contracts. You alone are responsible for securing your private keys. We do not have access
        to your private keys. Due to the non-custodial and decentralised nature of the technology,
        we are not intermediaries, agents, advisors, or custodians, and we do not have a fiduciary
        relationship or obligation to you regarding any other decisions or activities that you
        affect when using our Services. We are not responsible for any activities you engage in when
        using Services, and you should understand the risks associated with crypto assets,
        blockchain technology generally, and our Services.
      </p>
      <p>
        Blockchains and smart contracts are still emerging technologies that carry a relatively high
        amount of foreseeable and unforeseeable risk from security, financial, technical, political,
        social, and personal safety standpoints. The mere access to and interaction with blockchains
        and smart contracts requires a high degree of skill and knowledge to operate with a relative
        degree of safety and proficiency. Crypto assets are highly volatile in nature due to many
        diverse factors, including (without limitation) use and adoption, speculation, manipulation,
        technology, security, and legal and regulatory developments and application. Further, the
        speed and cost of transacting with cryptographic technologies (such as blockchains) is
        variable and highly volatile. Moreover, the transparent nature of many blockchains means
        that any interactions the User has with the Protocol may be publicly visible and readable in
        human form.
      </p>
      <p>
        By accessing and using the Interface, the User acknowledges the foregoing and agrees and
        represents that it understands such and other risks involved with blockchains and the
        Protocol. The User acknowledges that any crypto assets the User places or uses within the
        Protocol may increase or decrease in value rapidly while they are involved with the
        Protocol, or be obtained by other parties or otherwise lost in ways the User may or may not
        currently foresee (possibly to the User&rsquo;s severe harm or detriment). The User agrees
        that we are not responsible for any of these (or related) risks, do not own or control the
        Protocol, and cannot be held liable for any resulting harms, damages, or losses incurred by
        or against the User experiences while accessing or using the Interface. The User agrees to
        limit the extent of any liability, claims, causes of action, or damages arising from or in
        any way relating to the User&rsquo;s use of the Interface and the User&rsquo;s interaction
        with the Protocol to a maximum of USD$250,000.&nbsp;
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">9.1 . Services are Provided &ldquo;AS-IS&rdquo;</strong>
      </p>
      <p>
        THE SERVICES ARE PROVIDED ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS.
        USE OF THE SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
        THE SERVICES ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
        NON-INFRINGEMENT.
      </p>
      <p>
        WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS LICENSORS DO NOT WARRANT THAT THE
        CONTENT IS ACCURATE, RELIABLE, COMPLETE, OR CORRECT; THAT THE SERVICES WILL MEET YOUR
        REQUIREMENTS; THAT THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
        UNINTERRUPTED, ERROR-FREE, OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; THAT THE
        SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT THE SERVICES OR ITEMS
        OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR REQUIREMENTS OR EXPECTATIONS. TO THE
        FULLEST EXTENT PROVIDED BY LAW. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE TO YOUR
        COMPUTER SYSTEM, MOBILE DEVICE, DATA, OR OTHER PROPRIETARY MATERIAL THAT MAY RESULT FROM
        YOUR USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR YOUR DOWNLOADING OF ANY
        MATERIAL POSTED ON THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
        RESPONSIBILITY FOR ANY PRODUCT OR SERVICES ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
        THE SERVICES OR THIRD-PARTY LINKS, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
        TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES OR ANY OTHER
        USER.
      </p>
      <p>
        THE SERVICES WOULD NOT BE PROVIDED WITHOUT THESE LIMITATIONS. NO ADVICE OR INFORMATION,
        WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH THE SERVICES SHALL CREATE ANY
        WARRANTY, REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY
        NOT APPLY TO YOU. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICES,
        ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
      </p>
      <p>
        <br />
        <br />
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Indemnification</strong>
      </p>
      <p>
        You agree to indemnify, defend, and hold harmless the Company and its affiliates and their
        respective officers, directors, employees, agents, affiliates, successors, and permitted
        assigns (collectively, &ldquo;Indemnified Party&rdquo;) from and against any and all losses,
        claims, actions, suits, complaints, damages, liabilities, penalties, interest, judgments,
        settlements, deficiencies, disbursements, awards, fines, costs, fees, or expenses of
        whatever kind, including reasonable attorneys&rsquo; fees, fees and other costs of enforcing
        any right to indemnification under this Agreement, and the cost of pursuing any insurance
        providers, arising out of or relating to your breach of this Agreement or your use or misuse
        of the Services including, but not limited to, any actions taken by a third party using your
        account. The Company reserves the right, at your expense, to assume the exclusive defence
        and control of any matter for which you are required to indemnify us, and you agree to
        assist and cooperate with our defence or settlement of these claims.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Disputes</strong>
      </p>
      <p>
        <strong className="tg-title-h6">12.1. Governing Law</strong>
      </p>
      <p>
        All matters relating to this Agreement, and all matters arising out of or relating to this
        Agreement, whether sounding in contract, tort, or statute are governed by, and construed in
        accordance with, the laws of Singapore, without giving effect to any conflict of law
        principles.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">12.2. Dispute Resolution</strong>
      </p>
      <p>
        Any action or proceeding arising out of, or related to, this Agreement or the Services shall
        be brought only in a state or federal court located in the jurisdiction where the BNPL
        Foundation is domiciled from time to time, although we retain the right to bring any suit,
        action, or proceeding against you for breach of this Agreement in your country of residence
        or any other relevant country. You hereby irrevocably submit to the jurisdiction of these
        courts and waive the defence of inconvenient forum to the maintenance of any action or
        proceeding in such venues.
      </p>
      <p>
        At the Company&rsquo;s sole discretion, it may require any dispute, claim, or controversy
        arising out of or relating to this Agreement, or the breach, termination, enforcement,
        interpretation, or validity thereof, to be submitted to and decided by a single arbitrator
        by binding arbitration. The decision of the arbitrator shall be final and binding on the
        parties and may be entered and enforced in any court of competent jurisdiction by either
        party. The prevailing party in the arbitration proceedings shall be awarded reasonable
        attorneys&rsquo; fees, expert witness costs and expenses, and all other costs and expenses
        incurred directly or indirectly in connection with the proceedings, unless the arbitrator
        shall for good cause determine otherwise.
      </p>
      <p>
        All arbitrations shall proceed on an individual basis. You agree that you may bring claims
        against the Company in arbitration only in your individual capacities and in so doing you
        hereby waive the right to a trial by jury, to assert or participate in a class action
        lawsuit or class action arbitration (either as a named-plaintiff or class member), and to
        assert or participate in any joint or consolidated lawsuit or joint or consolidated
        arbitration of any kind. Notwithstanding anything to the contrary under the rules of the
        American Arbitration Association, the arbitrator may not consolidate more than one person's
        claims, and may not otherwise preside over any form of a representative or class proceeding.
        If a court decides that applicable law precludes enforcement of any of this paragraph's
        limitations as to a particular claim for relief, then that claim (and only that claim) must
        be severed from the arbitration and may be brought in court.
      </p>
      <p>
        YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU ARE WAIVING THE RIGHT TO
        TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">12.3. Limitation to Time to File Claims.</strong>
      </p>
      <p>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
        THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION AROSE;
        OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY WAIVED AND BARRED.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Miscellaneous</strong>
      </p>
      <p>
        <strong className="tg-title-h6">13.1. Waiver</strong>
      </p>
      <p>
        Except as otherwise set forth in this Agreement, no failure of the Company to exercise, or
        delay by the Company in exercising, any right, remedy, power, or privilege arising from this
        Agreement shall operate or be construed as a waiver thereof, nor shall any single or partial
        exercise of any right, remedy, power, or privilege hereunder preclude any other or further
        exercise thereof or the exercise of any other right, remedy, power, or privilege.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">13.2. Severability</strong>
      </p>
      <p>
        If any term or provision of this Agreement is found by a court of competent jurisdiction to
        be invalid, illegal, or unenforceable, such invalidity, illegality, or unenforceability
        shall not affect any other term or provision of this Agreement or invalidate or render
        unenforceable such term or provision in any other jurisdiction.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">13.3. Entire Agreement</strong>
      </p>
      <p>
        This Agreement, together with all documents referenced herein, constitutes the entire
        agreement between you and the Company with respect to the subject matter contained herein.
        This Agreement supersedes all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, with respect to the subject matter
        hereof.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">13.4. Headings</strong>
      </p>
      <p>
        Headings and titles of sections, clauses, and parts in this Agreement are for convenience
        only. Such headings and titles shall not affect the meaning of any provisions of the
        Agreement.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">13.5. No Agency, Partnership or Joint Venture</strong>
      </p>
      <p>
        No agency, partnership, or joint venture has been created between you and the Company as a
        result of this Agreement. You do not have any authority of any kind to bind the Company in
        any respect whatsoever.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">13.6. Assignment</strong>
      </p>
      <p>
        You shall not assign or delegate any of your rights or obligations under this Agreement
        without the prior written consent of the Company. Any purported assignment or delegation in
        violation of this Section shall be deemed null and void. No assignment or delegation shall
        relieve you of any of your obligations hereunder. The Company may freely assign or delegate
        its rights and obligations under this Agreement at any time. Subject to the limits on
        assignment stated above, this Agreement will inure to the benefit of, be binding on, and be
        enforceable against each of the parties hereto and their respective successors and assigns.
      </p>
      <br />
      <p>
        <strong className="tg-title-h6">Contact Information</strong>
      </p>
      <p>
        All feedback, comments, requests for technical support, and other communications relating to
        the Services should be directed to admin@bnplpay.co
      </p>
    </div>
  );
};

export default TermsAndConditions;
