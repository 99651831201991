import clsx from "clsx";

interface Props {
  className?: string;
  deafultClassName?: string;
}

export const Skeleton: React.FC<Props> = ({
  className = "w-24 h-6 block",
  deafultClassName = "bg-gray-300 rounded-md"
}) => <span className={clsx("animate-pulse", deafultClassName, className)} />;
