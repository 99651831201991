import clsx from "clsx";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AiFillCopy } from "react-icons/ai";

interface Props {
  text: string;
  className?: string;
}

export const CopyClipboard: React.FC<Props> = ({ text, className }) => {
  const { t } = useTranslation("common");
  const onClick = () => {
    navigator.clipboard.writeText(text);
    toast.success(t("copyClipboardMessage"));
  };
  return (
    <AiFillCopy
      className={clsx("cursor-pointer hover:text-primary-700", className)}
      onClick={onClick}
    />
  );
};
