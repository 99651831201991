import config from "contracts/config";

import BankingNode_ABI from "../contracts/abi/BankingNode.json";
import BNPLFactory_ABI from "../contracts/abi/BNPLFactory.json";
import RewardController_ABI from "../contracts/abi/RewardController.json";
import StakingRewards_ABI from "../contracts/abi/StakingRewards.json";
import SushiSwapLpToken_ABI from "../contracts/abi/SushiSwapLpToken.json";
import type {
  BankingNode,
  BNPLFactory,
  RewardController,
  StakingRewards,
  SushiSwapLpToken
} from "../contracts/types";
import useContract from "./useContract";

export function useBNPLFactoryContract() {
  return useContract<BNPLFactory>(config.BNPL_FACTORY, BNPLFactory_ABI);
}

export function useBankingNodeContract(address: string) {
  return useContract<BankingNode>(address, BankingNode_ABI);
}

export function useRewardControllerContract() {
  return useContract<RewardController>(config.REWARD, RewardController_ABI);
}

export function useStakingRewardContract() {
  return useContract<StakingRewards>(config.STAKING_REWARDS_CONTRACT, StakingRewards_ABI);
}

export function useStakingTokenContract() {
  return useContract<SushiSwapLpToken>(config.STAKING_TOKEN_ADDRESS, SushiSwapLpToken_ABI);
}
