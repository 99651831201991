import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cookies, getParsedCookies } from "configs/cookies";
import jsCookie from "js-cookie";
import { Theme } from "types/theme";
import { isClient } from "utils/common";

export interface ThemeState {
  value: Theme;
}

const parsedCookies = getParsedCookies();
// in order to change defaultTheme, consider to change HTML default class too
const defaultTheme: Theme = parsedCookies.theme || Theme.dark;

const handleThemeChange = (theme: Theme) => {
  if (theme === Theme.dark) document.documentElement.classList.add(Theme.dark, "ui-dark");
  else document.documentElement.classList.remove(Theme.dark, "ui-dark");

  jsCookie.set(cookies.theme, theme, { expires: 30 * 24 * 60 * 60 });
};
if (isClient) handleThemeChange(defaultTheme);

const initialState: ThemeState = {
  value: defaultTheme
};

export const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme | undefined>) => {
      // if no payload is provided, action will toggle the theme
      state.value = action?.payload
        ? action.payload
        : state.value === Theme.dark
        ? Theme.light
        : Theme.dark;

      handleThemeChange(state.value);
    }
  }
});

export const { setTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;
