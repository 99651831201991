import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { NotificationService } from "services/notification/notification.service";

export const useNotification = () => {
  const { account } = useWeb3React();

  const queryClient = useQueryClient();
  const { isLoading, error, data, refetch } = useQuery(
    ["notifications", account],
    async () => {
      try {
        const { data } = await NotificationService.getNotifications(account || "");
        return data;
      } catch (error) {}
    },
    {
      enabled: !!account,
      staleTime: 5 * 60000
    }
  );

  const readAll = useCallback(async () => {
    try {
      const notRead = data?.filter((item) => !item.read);
      if (!notRead || notRead?.length === 0) return;

      const { data: list } = await NotificationService.readAll(
        account || "",
        notRead.map((item) => item._id)
      );
      queryClient.setQueryData(["notifications", account], list);
      return list;
    } catch (error) {}
  }, [account, data, queryClient]);

  return {
    data: data || [],
    error,
    isLoading,
    refetch,
    readAll
  };
};
