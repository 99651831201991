import clsx from "clsx";

export interface SpinnerProps {
  variant?: "primary" | "secondary" | "success" | "danger" | "warning";
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ variant = "primary", className = "w-6 h-6" }) => {
  return (
    <i
      className={clsx(
        "flex rounded-full bg-transparent border-2 border-solid animate-spin",
        {
          "border-secondary border-t-primary-400": variant === "primary",
          "border-gray-100 dark:border-gray-300 border-t-green-700 dark:border-t-green-700":
            variant === "success",
          "border-gray-100 dark:border-gray-300 border-t-red-700 dark:border-t-red-700":
            variant === "danger",
          "border-gray-100 dark:border-gray-300 border-t-yellow-700 dark:border-t-yellow-700":
            variant === "warning",
          "border-primary-400 border-t-secondary": variant === "secondary"
        },
        className
      )}
    />
  );
};
