import clsx from "clsx";
import { ReactNode } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  className?: string;
  children?: ReactNode;
  defaultClass?: string;
  open: boolean;
  close: () => void;
}

export const Drawer: React.FC<Props> = ({
  open,
  className,
  defaultClass = "w-[80vw] py-12 px-4",
  children,
  close
}) => {
  return (
    <div
      aria-hidden="true"
      className={clsx(
        "top-0 right-0 fixed z-100 bg-overlay",
        {
          "h-full w-full": open
        },
        className
      )}
      onClick={() => close()}
    >
      <div
        aria-hidden="true"
        className={clsx(
          "max-w-xl top-0 right-0 fixed h-full ease-in-out duration-300 bg-white border border-gray-200 dark:bg-gray-900 dark:border-gray-700 dark:text-gray-400",
          {
            "translate-x-0": open,
            "translate-x-full": !open
          },
          defaultClass
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <AiOutlineClose className="absolute top-4 left-4 text-xl cursor-pointer" onClick={close} />
        {children}
      </div>
    </div>
  );
};
