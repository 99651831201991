import { Button } from "components/ui/Button/Button";
import { Dialog } from "components/ui/Dialog/Dialog";
import React, { useContext, useRef, useState } from "react";

type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide
  };
};

type ModalContextType = {
  showConfirmation: (title: string, message: string | JSX.Element) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (
  props
) => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<{ title: string; message: string | JSX.Element } | null>();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const resolver = useRef<Function>();

  const handleShow = (title: string, message: string | JSX.Element): Promise<boolean> => {
    setContent({
      title,
      message
    });
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}
      {content && (
        <Dialog disableClose hideCloseIcon open={show} onClose={onHide}>
          <h4 className="tg-body-bold">{content.title}</h4>
          {content.message && <div>{content.message}</div>}
          <div className="flex items-center justify-center mt-4">
            <Button className="mx-2" variant="danger" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="mx-2" variant="success" onClick={handleOk}>
              OK
            </Button>
          </div>
        </Dialog>
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useConfirmationModalContext, useModalShow };

export default ConfirmationModalContextProvider;
