import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { removeAuthorizationHeader, setAuthorizationHeader } from "api/httpClient";
import { cookies, getParsedCookies } from "configs/cookies";
import jsCookie from "js-cookie";

//the signature is valid for 3 days
export const TOKEN_EXPIRY = 259200;

export interface AuthState {
  address: string;
  signature: string;
  message: string;

  //TODO: Move to another slice
  approvedLoansCount: number;
  bankNodeAddress: string;
  bankNodePendingLoansCount: number;
}

interface AuthPayload {
  address: string;
  signature: string;
  message: string;
}

const parsedCookies = getParsedCookies();

console.log({
  parsedCookies
});
if (parsedCookies.address && parsedCookies.signature && parsedCookies.message) {
  setAuthorizationHeader(parsedCookies.address, parsedCookies.message, parsedCookies.signature);
}

const initialState: AuthState = {
  address: parsedCookies.address,
  signature: parsedCookies.signature,
  message: parsedCookies.message,
  approvedLoansCount: 0,
  bankNodeAddress: "",
  bankNodePendingLoansCount: 0
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthPayload>) => {
      state.address = action.payload.address;
      state.signature = action.payload.signature;
      state.message = action.payload.message;

      jsCookie.set(cookies.address, action.payload.address, { expires: TOKEN_EXPIRY });
      jsCookie.set(cookies.signature, action.payload.signature, { expires: TOKEN_EXPIRY });
      jsCookie.set(cookies.message, action.payload.message, { expires: TOKEN_EXPIRY });
    },
    setApprovedLoansCount(state, action: PayloadAction<number>) {
      state.approvedLoansCount = action.payload;
    },
    setBankNodeAddress(state, action: PayloadAction<string>) {
      state.bankNodeAddress = action.payload;
    },
    setBankNodePendingLoansCount(state, action: PayloadAction<number>) {
      state.bankNodePendingLoansCount = action.payload;
    },
    logout(state) {
      state.address = "";
      state.signature = "";
      state.message = "";

      jsCookie.remove(cookies.address);
      jsCookie.remove(cookies.signature);
      jsCookie.remove(cookies.message);

      removeAuthorizationHeader();
    }
  }
});

export const {
  setAuth,
  logout,
  setApprovedLoansCount,
  setBankNodeAddress,
  setBankNodePendingLoansCount
} = AuthSlice.actions;

export default AuthSlice.reducer;
