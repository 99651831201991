import clsx from "clsx";
import { InputHTMLAttributes } from "react";

import classes from "./Checkbox.module.scss";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  wrapperClassName?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  wrapperClassName,
  label,
  disabled,
  ...props
}) => {
  return (
    <div className={clsx("flex", wrapperClassName)}>
      <label
        className={clsx("inline-flex flex-row items-center w-full", className, classes.wrapper, {
          "opacity-50": disabled
        })}
      >
        <div
          className={clsx(
            "transition-colors relative flex items-center justify-center flex-shrink-0 w-5 h-5 mr-3 bg-gray-50 rounded cursor-pointer border border-edge dark:bg-gray-900",
            { "hover:bg-primary-100 hover:dark:bg-gray-600": !disabled }
          )}
        >
          <input
            className="absolute opacity-0 cursor-pointer w-full h-full"
            disabled={disabled}
            type="checkbox"
            {...props}
          />
          <svg
            className="hidden w-3.5 h-3.5 text-primary-700 dark:text-gray-50 pointer-events-none fill-current"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        {label && <span className="select-none cursor-pointer">{label}</span>}
      </label>
    </div>
  );
};
