import Tippy from "@tippyjs/react";
import { Card } from "components/Common/Card/Card";
import { Loader } from "components/Common/Loader/Loader";
import { Button } from "components/ui/Button/Button";
import { routes, RouteType } from "configs/routes";
import { useAuth } from "hooks/useAuth";
import { DefaultLayout } from "layouts/DefaultLayout";
import { ReactNode, Suspense } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Route, Routes } from "react-router-dom";

interface IPrivateRoute {
  children?: ReactNode;
}
const PrivateRoute: React.FC<IPrivateRoute> = ({ children }) => {
  const { isLoggedIn, login } = useAuth();

  if (!isLoggedIn) {
    return (
      <div className="flex flex-col items-center justify-center">
        <Card
          className="text-left my-20"
          defaultClass="flex-col text-title text-lg font-semibold px-10 py-8 rounded-xl inline-flex items-cetner justify-center"
        >
          <p className="flex items-center mb-1">
            Sign in to continue to you account
            <Tippy content="You are not asked to pay or create a transaction when signing in, it is simply a mechanism used by Web3 developers to authenticate users.">
              <span>
                <BiInfoCircle className="ml-1 text-xl" />
              </span>
            </Tippy>
          </p>

          <Button className="mt-4" onClick={login}>
            Sign in with Wallet
          </Button>
        </Card>
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const App: React.FC = () => {
  const renderRoutesTree = (routes: Readonly<RouteType[]>) =>
    routes.map((route, index) => {
      if (route.routes?.length) {
        return (
          <Route key={index} {...route}>
            {renderRoutesTree(route.routes)}
          </Route>
        );
      } else if (route.isPrivate) {
        return (
          <Route key={index} {...route} element={<PrivateRoute>{route.element}</PrivateRoute>} />
        );
      }
      return <Route key={index} {...route} />;
    });

  return (
    <Suspense fallback={<Loader fullScreen />}>
      <Routes>
        <Route element={<DefaultLayout />}>{renderRoutesTree(routes)}</Route>
      </Routes>
    </Suspense>
  );
};
