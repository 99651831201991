import { useWeb3React } from "@web3-react/core";
import { Badge } from "components/Common/Badge/Badge";
import { Loader } from "components/Common/Loader/Loader";
import { ApplicantsTable } from "components/pages/kyc/ApplicantsTable";
import { UpdateKYC } from "components/pages/kyc/UpdateKYC";
import { Button } from "components/ui/Button/Button";
import { paths } from "configs/paths";
import { isAddress } from "ethers/lib/utils";
import { useBNPLFactoryContract } from "hooks/useContracts";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BankNodeService } from "services/bankNode/bankNode.service";
import { ApplicantWithStatuses } from "services/bankNode/bankNode.types";
import { compareAddress } from "utils/common";

const Applicants: React.FC = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [applicants, setApplicants] = useState<ApplicantWithStatuses[]>([]);
  const factoryContract = useBNPLFactoryContract();
  const { account } = useWeb3React();

  const fetchData = useCallback(async () => {
    setList([]);
    try {
      if (address) {
        setLoading(true);
        const { data } = await BankNodeService.getApplicants(address);
        setApplicants(data.list.items.filter((item) => isAddress(item.applicant.externalUserId)));
      }
    } catch (error) {
      setError(true);
      // navigate(paths.bankNodes.index);
    } finally {
      setLoading(false);
    }
  }, [address]);

  const init = useCallback(async () => {
    const nodeAddress = await factoryContract?.operatorToNode(account!);
    if (!compareAddress(nodeAddress, address)) {
      navigate(paths.bankNodes.index);
    } else {
      fetchData();
    }
  }, [account, address, factoryContract, fetchData, navigate]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader fullScreen overlay />;

  return (
    <>
      {error ? (
        <div className="pt-10 flex items-center justify-around">
          <Badge variant="danger">
            Could not get the list make sure your token is set correctly
          </Badge>
          <Button onClick={() => setShowEdit(true)}>Edit KYC</Button>
        </div>
      ) : (
        <ApplicantsTable
          action={<Button onClick={() => setShowEdit(true)}>Edit KYC</Button>}
          applicants={applicants}
          fetchData={fetchData}
          list={list}
          setList={setList}
        />
      )}
      {address && (
        <UpdateKYC bankNodeAddress={address} setShowEdit={setShowEdit} showEdit={showEdit} />
      )}
    </>
  );
};

export default Applicants;
