import { Badge, BadgeVariant } from "components/Common/Badge/Badge";
import React from "react";
import { ReviewStatus } from "services/bankNode/bankNode.types";

type ApplicantStatusProps = {
  reviewStatus: ReviewStatus;
};

const status = {
  completed: "success",
  init: "default",
  pending: "yellow",
  prechecked: "indiego",
  queued: "indiego",
  onHold: "danger"
};
export const ApplicantStatus: React.FC<ApplicantStatusProps> = ({ reviewStatus }) => {
  return (
    <Badge variant={status[reviewStatus] as BadgeVariant}>
      {reviewStatus === "init" ? "Incomplete" : reviewStatus}
    </Badge>
  );
};
