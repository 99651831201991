import { RiMenu3Line } from "react-icons/ri";

interface Props {
  onClick: () => void;
}

export const HeaderBurger: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      className="lg:hidden bg-secondary dark:bg-primary-700 rounded-full w-12 h-12 flex items-center justify-center ml-2"
      onClick={onClick}
    >
      <RiMenu3Line className="text-xl " />
    </button>
  );
};
