import { Contract } from "ethers";

import { infuraProvider } from "./infura";

export const multicall = new Contract(
  "0xcA11bde05977b3631167028862bE2a173976CA11",
  [
    "function aggregate(tuple(address target, bytes callData)[] calls) payable returns (uint256 blockNumber, bytes[] returnData)",
    "function aggregate3(tuple(address target, bool allowFailure, bytes callData)[] calls) payable returns (tuple(bool success, bytes returnData)[] returnData)"
  ],
  infuraProvider
);
