import { useWeb3React } from "@web3-react/core";
import clsx from "clsx";
import { useMemo } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineLoading3Quarters
} from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { formatEtherscanLink } from "utils";
import { currentChainId } from "utils/wallet/connectors";

interface Props {
  className?: string;
  items: StepperItem[];
  current: number;
  error?: boolean;
}
export interface StepperItem {
  label: string;
}
export const Stepper: React.FC<Props> = ({ className, items, current, error }) => {
  const { account } = useWeb3React();
  const etherscanURL = useMemo(
    () => formatEtherscanLink("Account", [currentChainId, account!]),
    [account]
  );
  return (
    <ul className={clsx(className, "flex flex-col gap-4")}>
      {items.map((item, i) => (
        <li key={i} className="flex items-center">
          <StepperStatus
            active={current > i + 1}
            error={current === i + 1 && !!error}
            index={i + 1}
            loading={current === i + 1 && !error}
          />
          {item.label}
        </li>
      ))}
      <a
        className="flex items-center mx-auto text-sm hover:text-primary-700 transition-colors text-primary-700"
        href={etherscanURL}
        rel="noreferrer"
        target="_blank"
      >
        View on Etherscan <BiLinkExternal className="ml-1" />
      </a>
    </ul>
  );
};

interface StepperStatusProps {
  className?: string;
  index: number;
  error: boolean;
  loading: boolean;
  active: boolean;
}
export const StepperStatus: React.FC<StepperStatusProps> = ({ error, loading, active, index }) => {
  if (error) return <AiOutlineCloseCircle className="w-6 h-6 mr-2 text-red-600" />;
  if (active) return <AiOutlineCheckCircle className="w-6 h-6 mr-2 text-green-600" />;
  if (loading)
    return (
      <div className="w-6 h-6 mr-2">
        <AiOutlineLoading3Quarters className="w-full h-full animate-spin" />
      </div>
    );

  return (
    <div className="select-none w-6 h-6 mr-2 border dark:border-gray-400 flex items-center justify-center rounded-full text-sm opacity-75">
      {index}
    </div>
  );
};
