import { isMainnet } from "utils/common";

const PRODUCTION_CONFIG = {
  BNPL_FACTORY: "0x7edB0c8b428b97eA1Ca44ea9FCdA0835FBD88029",
  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  DAI: "0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa",
  WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  WETH: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  BUSD: "0x4c6E1EFC12FDfD568186b7BAEc0A43fFfb4bCcCf",
  REWARD: "0x5B9B2f05be3fEBFfEA574325D7247b1c40D822F9",
  BNPL: "0x84d821f7fbdd595c4c4a50842913e6b1e07d7a53",
  REWARDS_TOKEN_ADDRESS: "0x84d821f7fbdd595c4c4a50842913e6b1e07d7a53", // bnpl token
  STAKING_TOKEN_ADDRESS: "0x817211D408fbc19193e482f4900354e4CC894A44", //SushiSwap LP Token (SLP)
  STAKING_REWARDS_CONTRACT: "0x864B2BAC60311F15Ba539abc463FB71284dc2444" // StakingSushiSwap LP Token (SLP) Rewards proxy
};
const GOERLI_CONFIG = {
  BNPL: "0xD7121A7Ba390d6f39d4cEa61Fed3d7F1F69B1b70",
  BNPL_FACTORY: "0x74c79B08Fe41c89509549F0975C8435D951C5967",
  REWARD: "0xc658f3afF25Aa0Ce1B9A53487F6FD23AA03FBC07",
  USDT: "0x65e2fe35c30ec218b46266f89847c63c2eda7dc7",
  USDC: "0x2f3a40a3db8a7e3d09b0adfefbce4f6f81927557",
  DAI: "0x73967c6a0904aa032c103b4104747e88c566b1a2",
  WBTC: "0xc04b0d3107736c32e19f1c62b2af67be61d63a05",
  WETH: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
  BUSD: "0xb809b9b2dc5e93cb863176ea2d565425b03c0540",
  REWARDS_TOKEN_ADDRESS: "0xd8aDeb6272b657bF6f04088c405Fcb4Cc4ee859f", // bnpl token
  STAKING_TOKEN_ADDRESS: "0xc2c527c0cacf457746bd31b2a698fe89de2b6d49", // USDT
  STAKING_REWARDS_CONTRACT: "0x104bef2e39612559c852beb1da2ec425357b3840" // SushiSwap LP Token (SLP) BankNodeLendingRewardsProxy
};
// const KOVAN_CONFIG = {
//   BNPL_FACTORY: "0x618D8244F4c958166096c316668A9dE11269bB49",
//   USDT: "0x13512979ADE267AB5100878E2e0f485B568328a4",
//   USDC: "0xe22da380ee6b445bb8273c81944adeb6e8450422",
//   DAI: "0xff795577d9ac8bd7d90ee22b6c1703490b6512fd",
//   WBTC: "0xd1b98b6607330172f1d991521145a22bce793277",
//   WETH: "0xd0a1e359811322d97991e03f863a0c30c2cf029c",
//   BUSD: "0x4c6E1EFC12FDfD568186b7BAEc0A43fFfb4bCcCf",
//   REWARD: "0xaC32ea958eAbcaAa08b07d1B5103EeAe877cBFE9",
//   BNPL: "0xd5D3986ac60C706E1388D01890142b507f95763a",
//   REWARDS_TOKEN_ADDRESS: "0xd5D3986ac60C706E1388D01890142b507f95763a", // bnpl token
//   STAKING_TOKEN_ADDRESS: "0x13512979ADE267AB5100878E2e0f485B568328a4", // USDT
//   STAKING_REWARDS_CONTRACT: "0x104bef2e39612559c852beb1da2ec425357b3840" // SushiSwap LP Token (SLP) BankNodeLendingRewardsProxy
// };

export default isMainnet ? PRODUCTION_CONFIG : GOERLI_CONFIG;
