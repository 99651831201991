import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import commonEN from "locales/en/common.json";
import formsEN from "locales/en/forms.json";
import layoutsEN from "locales/en/layouts.json";
import { initReactI18next } from "react-i18next";
import { Locale } from "types/locale";

enum NameSpace {
  common = "common",
  forms = "forms",
  layouts = "layouts"
}

const resources = {
  en: {
    common: commonEN,
    forms: formsEN,
    layouts: layoutsEN
  }
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: Locale.en,
    resources,
    defaultNS: NameSpace.common,
    fallbackLng: Locale.en,
    interpolation: {
      escapeValue: false
    },
    detection: {
      caches: ["cookie"]
    }
  });
