import { useWeb3React } from "@web3-react/core";
import { useQuery } from "react-query";
import { ChatService } from "services/chat/chat.service";

import { useAppSelector } from "./common/redux";
import { useGetBankNodeAddress } from "./useGetBankNodeAddress";

export const useNewMessageCount = () => {
  const { account } = useWeb3React();
  const { address } = useAppSelector((state) => state.auth);
  const { isLoading, error, data, refetch } = useQuery(
    ["messages", address],
    async () => {
      try {
        const { data } = await ChatService.getNewMessageCount(account!);
        return Object.keys(data).map((user) => ({
          user,
          count: data[user]
        }));
      } catch (error) {}
    },
    {
      staleTime: 10 * 60000,
      enabled: !!account
    }
  );

  return {
    data: data || [],
    error,
    isLoading,
    refetch
  };
};

export const useNodeNewMessageCount = () => {
  const { account } = useWeb3React();
  const { bankNodeAddress } = useGetBankNodeAddress();
  const { isLoading, error, data, refetch } = useQuery(
    ["messages", bankNodeAddress],
    async () => {
      try {
        const { data } = await ChatService.getNodeNewMessageCount(account!);
        return Object.keys(data).map((user) => ({
          user,
          count: data[user]
        }));
      } catch (error) {}
    },
    {
      staleTime: 10 * 60000,
      enabled: !!account && !!bankNodeAddress
    }
  );

  return {
    data: data || [],
    error,
    isLoading,
    refetch
  };
};
