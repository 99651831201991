import { BigNumber } from "ethers";
import { useCallback } from "react";

import useTokenContract from "./useTokenContract";

export default function useSendToken(tokenAddress: string) {
  const contract = useTokenContract(tokenAddress);

  const send = useCallback(
    async (to: string, value: BigNumber) => {
      try {
        const tx = await contract?.transfer(to, value);
        console.log("tx:", tx);
        const receipt = await tx?.wait();
        console.log("receipt:", receipt);
      } catch (error) {
        console.log("transfer error", error);
      }
    },
    [contract]
  );

  return {
    send
  };
}
